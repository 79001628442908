import React from 'react'
import PropTypes from 'prop-types'

import Header from './header'
import Body from './body'
import Footer from './footer'

import * as Styles from './styles'

const PlanDetails = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'customerIsPausedWithCoupon' impli... Remove this comment to see the full error message
  customerIsPausedWithCoupon,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasCardOnFile' implicitly has an ... Remove this comment to see the full error message
  hasCardOnFile,
  // @ts-expect-error TS(7031) FIXME: Binding element 'noTrialStarted' implicitly has an... Remove this comment to see the full error message
  noTrialStarted,
  // @ts-expect-error TS(7031) FIXME: Binding element 'paidPlanMessage' implicitly has a... Remove this comment to see the full error message
  paidPlanMessage,
  // @ts-expect-error TS(7031) FIXME: Binding element 'planDetails' implicitly has an 'a... Remove this comment to see the full error message
  planDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'product' implicitly has an 'any' ... Remove this comment to see the full error message
  product,
  // @ts-expect-error TS(7031) FIXME: Binding element 'updatePlanCallback' implicitly ha... Remove this comment to see the full error message
  updatePlanCallback,
  // @ts-expect-error TS(7031) FIXME: Binding element 'trialExpired' implicitly has an '... Remove this comment to see the full error message
  trialExpired,
  // @ts-expect-error TS(7031) FIXME: Binding element 'openCancelPlanModal' implicitly h... Remove this comment to see the full error message
  openCancelPlanModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isPayingWithBank' implicitly h... Remove this comment to see the full error message
  isPayingWithBank,
}) => {
  return (
    <Styles.PlanDetailsWrapper>
      <Header
        trialExpired={trialExpired}
        noTrialStarted={noTrialStarted}
        planDetails={planDetails}
        customerIsPausedWithCoupon={customerIsPausedWithCoupon}
      />
      <Body
        trialExpired={trialExpired}
        hasCardOnFile={hasCardOnFile}
        noTrialStarted={noTrialStarted}
        paidPlanMessage={paidPlanMessage}
        planDetails={planDetails}
      />
      {!isPayingWithBank && (
        <Footer
          planDetails={planDetails}
          trialExpired={trialExpired}
          product={product}
          updatePlanCallback={updatePlanCallback}
          noTrialStarted={noTrialStarted}
          openCancelPlanModal={openCancelPlanModal}
        />
      )}
    </Styles.PlanDetailsWrapper>
  )
}

PlanDetails.propTypes = {
  hasCardOnFile: PropTypes.bool,
  noTrialStarted: PropTypes.bool,
  paidPlanMessage: PropTypes.string,
  product: PropTypes.string,
  planDetails: PropTypes.shape({
    cycle: PropTypes.string,
    discount: PropTypes.shape({
      name: PropTypes.string,
      discount: PropTypes.number,
      type: PropTypes.string,
    }),
    cycleEndDate: PropTypes.number,
    price: PropTypes.number,
    subscriptionCanceledDate: PropTypes.number,
    subscriptionEndDate: PropTypes.number,
    subscriptionType: PropTypes.string,
    trialEndDate: PropTypes.number,
  }),
  trialExpired: PropTypes.bool,
  updatePlanCallback: PropTypes.func,
  customerIsPausedWithCoupon: PropTypes.bool,
  openCancelPlanModal: PropTypes.func,
}

PlanDetails.defaultProps = {
  discounts: null,
  planDetails: {},
}

export default PlanDetails

import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { Link, Modal, Text } from '@bufferapp/ui'
import { Cross, Warning } from '@bufferapp/ui/Icon'
import React, { useEffect } from 'react'
import ReactPlayer from 'react-player'
import { useDispatch } from 'react-redux'
import { ModalTitle } from '../../../../../../../account-components'

import { actions } from '../../../../../../../channel-list/reducer'

import { ChannelThirdPartyService } from '../../../../../../../channel-connections/interfaces'
import { capitalizeFirstLetter } from '../../../../../../../shared-utils'
import { ChannelType } from '../../../../../hooks/channel-connections/types'
import {
  getGuideUrl,
  getServiceNameForBody,
  isInstagramOrFacebook,
  isPinterestOrTwitter,
} from '../../../utils/channels'
import {
  Body,
  IconWrapper,
  LeftIconWrapper,
  ModalHeader,
} from '../Modal/styles'

interface NoChannelsErrorProps {
  onClose: () => void
  service: ChannelThirdPartyService
  type: ChannelType
}
const NoChannelsError = ({
  onClose = (): void => undefined,
  service = ChannelThirdPartyService.facebook,
  type = ChannelType.page,
}: NoChannelsErrorProps): JSX.Element => {
  const dispatch = useDispatch()

  useEffect(() => {
    BufferTracker.pageViewed({
      channel: service,
      channelType: type,
      name: 'Channel connection error notice',
      path: window.location.pathname || null,
      ctaApp: 'account',
      cta: 'channel-connect',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    // @ts-expect-error TS(2322)
    <Modal
      secondaryAction={{
        label: 'Close',
        callback: (): void => {
          dispatch(actions.close())
          onClose()
        },
      }}
      dismissible={false}
    >
      <ModalHeader>
        <LeftIconWrapper>
          <IconWrapper>
            <Warning />
          </IconWrapper>
        </LeftIconWrapper>
        {!isPinterestOrTwitter(service) && (
          <ModalTitle
            text={`Whoops, we couldn’t find any ${capitalizeFirstLetter(
              service,
            )} ${capitalizeFirstLetter(type)}s connected to that account.`}
          />
        )}
        {isPinterestOrTwitter(service) && (
          <ModalTitle text="Sorry, the channel you are trying to connect is unavailable" />
        )}
        <IconWrapper>
          <Cross
            onClick={(): void => {
              dispatch(actions.close())
              onClose()
            }}
          />
        </IconWrapper>
      </ModalHeader>
      {!isPinterestOrTwitter(service) && (
        <Body>
          <Text type="p">
            Make sure you’re signing in with the{' '}
            {getServiceNameForBody(service)} account that has admin permissions
            for the {capitalizeFirstLetter(type)} you’re looking to connect.
            <br />
            <br />
            For more details, check out our{' '}
            <Link href={getGuideUrl(service, type)}>
              {capitalizeFirstLetter(service)} connection guide
            </Link>
            .
            <br />
            <br />
            {isInstagramOrFacebook(service) && (
              <ReactPlayer
                url="https://buffer.wistia.com/medias/ck3itlz8kb"
                width="480px"
                height="272px"
              />
            )}
          </Text>
        </Body>
      )}
      {isPinterestOrTwitter(service) && (
        <Body>
          <Text type="p">
            Looks like that channel is already connected to another Buffer
            organization.
            <br />
            <ul>
              <li>
                For security reasons, each channel may only be connected to one
                organization at a time.
              </li>
              <li>
                Remove the channel from the other organization to make it
                available to connect.
              </li>
            </ul>
            <Link href={getGuideUrl(service, type)}>
              Visit this Help Center article
            </Link>{' '}
            for more information on the issue.
          </Text>
        </Body>
      )}
    </Modal>
  )
}

export default NoChannelsError

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useSplitEnabled } from '@buffer-mono/features'
import { useHistory, useLocation } from 'react-router-dom'

import { actions } from '../../../../../channel-list/reducer'
import {
  BlockWrapper,
  PageLayout,
  TitleWrapper,
  WarningMessage,
} from '../../../../../account-components'
import { Button, Notice, Notification, Text } from '@bufferapp/ui'

import ChannelsBody from '../components/ChannelsBody'
import ChannelsCounter from '../components/ChannelsCounter'
import ChannelUnlockingModal from './components/ChannelUnlockingModal'
import PermissionsErrorModal from './components/PermissionsErrorModal'
import RemoveChannelModal from './components/RemoveChannelModal'
import SkeletonChannels from '../components/SkeletonChannels'
import PricingNotice from '../components/PricingNotice'

import { GET_CHANNELS_FOR_ORGANIZATION } from '../../../apollo/queries/channels'
import { REMOVE_CHANNELS } from '../../../apollo/mutations/channels'

import { getChannelsCookies, cleanupOauthCookie } from '../utils/cookies'
import {
  WarningMessageWrapper,
  NoticeWrapper,
  BlockWrapperStyled,
  ButtonsWrapper,
  ChannelsListWrapper,
} from '../style'
import {
  getCurrentPlanFromAccount,
  shouldShowUpgradePaths,
} from '../../Billing/utils/planHelpers'
import { useAccount } from '../../../context/Account'
import { handleChannelConnectionSuccessModalFlow } from '../utils/modals'

import { shouldShowAddOrRemoveAction } from './utils'
import { getProductUrl, isTrialUser } from '../../../../../shared-utils'
import { TRIAL_CHANNELS_LIMIT } from '../utils/channels'
import { isPaymentMethodBank } from '../../../utils/isPaymentMethodBank'
import type { SelectChannelToRemoveProps } from '../components/CardRow/types'
import { isOnNonAgencyTieredPricing } from '../../Billing/utils/isOnNonAgencyTieredPricing'
import type { AppLocationState } from '../../App/App'

export const fallbackAvatarUrl =
  'https://s3.amazonaws.com/buffer-ui/Default+Avatar.png'

export const clearChannel = {
  name: null,
  avatar: null,
  service: null,
  descriptor: null,
}

// Memoizing to avoid re-rendering and the notification popping up multiple times

const MemoizedNotifications = React.memo(function MemoizedNotifications({
  // @ts-expect-error TS(2339) FIXME: Property 'actions' does not exist on type '{ child... Remove this comment to see the full error message
  actions,
  // @ts-expect-error TS(2339) FIXME: Property 'shouldShowChannelConnectedModal' does no... Remove this comment to see the full error message
  shouldShowChannelConnectedModal,
  // @ts-expect-error TS(2339) FIXME: Property 'facebookGroupConnected' does not exist o... Remove this comment to see the full error message
  facebookGroupConnected,
  // @ts-expect-error TS(2339) FIXME: Property 'shouldShowChannelReconnectedModal' does ... Remove this comment to see the full error message
  shouldShowChannelReconnectedModal,
  // @ts-expect-error TS(2339) FIXME: Property 'channelsRemoveResponseStatus' does not e... Remove this comment to see the full error message
  channelsRemoveResponseStatus,
  // @ts-expect-error TS(2339) FIXME: Property 'hasReconnectError' does not exist on typ... Remove this comment to see the full error message
  hasReconnectError,
  // @ts-expect-error TS(2339) FIXME: Property 'hasReconnectMissing' does not exist on t... Remove this comment to see the full error message
  hasReconnectMissing,
  // @ts-expect-error TS(2339) FIXME: Property 'setHasReconnectMissing' does not exist o... Remove this comment to see the full error message
  setHasReconnectMissing,
  // @ts-expect-error TS(2339) FIXME: Property 'setHasReconnectError' does not exist on ... Remove this comment to see the full error message
  setHasReconnectError,
  // @ts-expect-error TS(2339) FIXME: Property 'setIsReconnecting' does not exist on typ... Remove this comment to see the full error message
  setIsReconnecting,
}) {
  const dispatch = useDispatch()

  return (
    <>
      {shouldShowChannelConnectedModal && !facebookGroupConnected && (
        <Notification
          text={'You’re all set! Your channel has been connected.'}
          onClose={(): void => {
            dispatch(actions.hideChannelConnectSuccessNotification())
          }}
        />
      )}

      {shouldShowChannelReconnectedModal && !facebookGroupConnected && (
        <Notification
          text={'You’re all set! Your channel has been refreshed.'}
          onClose={(): void => {
            dispatch(actions.hideChannelConnectSuccessNotification())
            setIsReconnecting(false)
          }}
        />
      )}

      {channelsRemoveResponseStatus.complete &&
        channelsRemoveResponseStatus.success && (
          <Notification
            text={'You’re all set! Your channel has been removed.'}
            onClose={(): void => {
              dispatch(actions.hideChannelConnectSuccessNotification())
            }}
          />
        )}

      {channelsRemoveResponseStatus.complete &&
        !channelsRemoveResponseStatus.success && (
          <Notification
            text={
              'Looks like we’ve hit a snag removing your channel. Would you mind trying again?'
            }
            onClose={(): void => {
              // do nothing
            }}
          />
        )}

      {hasReconnectError && (
        <Notification
          text={
            hasReconnectMissing
              ? 'It looks like you may be signed into the wrong account to refresh this channel. Would you mind checking and trying again?'
              : 'Looks like we’ve hit a snag refreshing your channel. Would you mind trying again?'
          }
          onClose={(): void => {
            setHasReconnectMissing(false)
            setHasReconnectError(false)
          }}
        />
      )}
    </>
  )
})

const refreshAccountInAppShell = (): void => {
  const { actions } = window?.appshell || {}
  if (actions) {
    actions.refetchAccount()
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ChannelsPage = ({ refetch }: { refetch: any }): JSX.Element => {
  /**
   * --------------------------------------------------------------------------
   * This page is being deprecated in favor of the new channels page.
   * Redirect if the feature flag is enabled.
   * --------------------------------------------------------------------------
   */
  const { isEnabled: isChannelSettingsRefreshEnabled } = useSplitEnabled(
    'channel-settings-refresh',
  )
  if (isChannelSettingsRefreshEnabled) {
    const url = getProductUrl('publish') + '/settings/channels'
    window.location.replace(url)
  }
  /**
   * --------------------------------------------------------------------------
   */

  const dispatch = useDispatch()
  const account = useAccount()
  const location = useLocation<AppLocationState>()
  const history = useHistory()

  const isOnTieredPricing = isOnNonAgencyTieredPricing(account)

  const { isEnabled: isChannelStorefrontEnabled } = useSplitEnabled(
    'growth-new-channel-store-front',
  )
  const { isEnabled: isRedirectToStorefront } = useSplitEnabled(
    'growth-redirect-channel-storefront',
    {
      email: account?.email,
    },
  )

  useEffect(() => {
    // Refetch account query in app shell to get latest channel data.
    // For example: channel reconnection since we rely on channel data to
    // prompt users to refresh their connection. Once they have refreshed,
    // we rely on the updated data to remove the prompt.
    refreshAccountInAppShell()
  }, [])

  useEffect(() => {
    if (location.state?.shouldShowChannelStorefront) {
      const { actions } = window?.appshell || {}
      actions.openChannelStorefront({
        cta: 'account-channels-redirect-noClick-1',
      })

      // Update the history stack and state to prevent the modal from showing again on refresh
      history.replace('/channels', {
        shouldShowChannelStorefront: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.shouldShowChannelStorefront])

  const timeToAutoHideNotification = 6000
  const [showChannelUnlockingModal, setShowChannelUnlockingModal] =
    useState(false)

  const contextAccount = useAccount()

  const isPayingWithBank = isPaymentMethodBank(contextAccount)

  const currentPlan = getCurrentPlanFromAccount(contextAccount)
  const shouldShowBillingActions = shouldShowUpgradePaths(
    contextAccount?.currentOrganization,
    currentPlan,
  )

  // @ts-expect-error TS(2339) FIXME: Property 'channelList' does not exist on type 'Def... Remove this comment to see the full error message
  const channelStatus = useSelector((state) => state.channelList)
  const [getChannelList, { data, loading, called }] = useLazyQuery(
    GET_CHANNELS_FOR_ORGANIZATION,
  )

  const [showChannelsUpdatedNotification, setShowChannelsUpdatedNotification] =
    useState(false)

  const [channelsRemoveResponseStatus, setChannelsRemoveResponseStatus] =
    useState({ complete: false, success: false })

  function showChannelRemovalSuccess(): void {
    setChannelsRemoveResponseStatus({ complete: true, success: true })
    setTimeout(() => {
      setChannelsRemoveResponseStatus({ complete: false, success: true })
    }, timeToAutoHideNotification)
  }

  function showChannelRemovalError(): void {
    setChannelsRemoveResponseStatus({ complete: true, success: false })
    setTimeout(() => {
      setChannelsRemoveResponseStatus({ complete: false, success: false })
    }, timeToAutoHideNotification)
  }

  const [removeChannels] = useMutation(REMOVE_CHANNELS, {
    onCompleted(channelsRemoveResponse) {
      if (channelsRemoveResponse.channelsRemove) {
        showChannelRemovalSuccess()
        refetch()
        refreshAccountInAppShell()
      } else {
        showChannelRemovalError()
      }
    },
    onError() {
      showChannelRemovalError()
    },
    refetchQueries: [{ query: GET_CHANNELS_FOR_ORGANIZATION }],
  })

  useEffect(() => {
    if (!called) {
      getChannelList()
    }
  }, [called])

  const [selectedChannelToRemove, selectChannelToRemove] =
    useState<SelectChannelToRemoveProps>()

  useEffect(() => {
    if (selectedChannelToRemove?.confirmed) {
      removeChannels({
        variables: {
          channelIds: [selectedChannelToRemove.id],
        },
      })
      selectChannelToRemove(undefined)
    }
  }, [selectedChannelToRemove, removeChannels])

  const [hasReconnectError, setHasReconnectError] = useState(false)
  const [hasReconnectMissing, setHasReconnectMissing] = useState(false)
  const [hasReconnectPermissionsError, setHasReconnectPermissionsError] =
    useState(false)
  const [isReconnecting, setIsReconnecting] = useState(false)
  const [oauthFlowResponse, setOauthFlowResponse] = useState({ success: false })
  const [facebookGroupConnected, setFacebookGroupConnected] = useState(false)

  useEffect(() => {
    if (window.location.search.match('connected=true')) {
      // @ts-expect-error TS(7034) FIXME: Variable 'channelId' implicitly has type 'any' in ... Remove this comment to see the full error message
      let channelId
      setOauthFlowResponse({ success: true })
      setTimeout(() => {
        setOauthFlowResponse({ success: false })
      }, timeToAutoHideNotification)
      dispatch(push('/channels'))
      setTimeout(() => {
        // @ts-expect-error TS(7005) FIXME: Variable 'channelId' implicitly has an 'any' type.
        handleChannelConnectionSuccessModalFlow([channelId])
      }, 1000)
    }
    if (window.location.search.match('reconnect=false')) {
      if (window.location.search.match('missing=true')) {
        setHasReconnectMissing(true)
        setHasReconnectError(true)
      } else if (window.location.search.match('permissions=true')) {
        setHasReconnectPermissionsError(true)
      } else {
        setHasReconnectError(true)
      }
      setTimeout(() => {
        setHasReconnectMissing(false)
        setHasReconnectError(false)
      }, timeToAutoHideNotification)
      dispatch(push('/channels'))
    }
  })

  useEffect(() => {
    const { oauthChannelsType } = getChannelsCookies()

    if (
      channelStatus &&
      channelStatus.showChannelConnectSuccessNotification &&
      oauthChannelsType === 'group'
    ) {
      setFacebookGroupConnected(true)
      cleanupOauthCookie()
    }
  })

  const navigateToConnectChannelsPage = (): void => {
    dispatch(push('/channels/connect'))
  }

  const openChannelStoreFront = (): void => {
    const { actions } = window?.appshell || {}
    actions.openChannelStorefront({
      cta: 'account-channels-pageHeader-connectChannel-1',
    })
  }

  const currentOrganization = !data
    ? { privileges: { canManageChannels: false } }
    : data?.account?.currentOrganization
  const { canManageChannels: canEdit } = currentOrganization.privileges
  const channels = !data
    ? []
    : [...data.account.currentOrganization.channels].sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      )

  const isOnTrial = isTrialUser(contextAccount)

  const limit = isOnTrial
    ? TRIAL_CHANNELS_LIMIT
    : data?.account.currentOrganization.limits.channels
  const channelsLimit = !data ? 0 : limit

  const lockedChannelsCount = channels.filter(function (e: any) {
    return e.isLocked
  }).length

  if (data && channels.length === 0) {
    if (isRedirectToStorefront) {
      openChannelStoreFront()
    } else {
      navigateToConnectChannelsPage()
    }
  }

  const shouldShowChannelConnectedModal =
    channelStatus &&
    ((channelStatus.showChannelConnectSuccessNotification &&
      !channelStatus.isReconnecting) ||
      oauthFlowResponse.success)
  const shouldShowChannelReconnectedModal =
    channelStatus &&
    ((channelStatus.showChannelConnectSuccessNotification &&
      channelStatus.isReconnecting) ||
      isReconnecting)

  const openSubscriptionUpdate = (): void => {
    const { MODALS, actions } = window?.appshell || {}
    actions.openModal(MODALS.subscriptionUpdate, {
      cta: 'channels-title-addOrRemoveChannels-1',
      upgradePathName: 'accountChannels-upgrade',
      shouldPickModalOnOrganizationState: true,
    })
  }

  const openTieredPricingModal = (): void => {
    const { MODALS, actions } = window?.appshell || {}
    actions.openModal(MODALS.quantityUpdateTieredPricing, {
      cta: 'channels-title-addRemoveChannels-1',
      upgradePathName: 'accountChannels-upgrade',
    })
  }

  const connectedChannels = channels.filter((c: any) => !c.isLocked)

  return (
    <>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: any[]; withSidebar: true; withAp... Remove this comment to see the full error message */}
      <PageLayout withSidebar withAppShell withOrgSwitcher refetch={refetch}>
        <MemoizedNotifications
          // @ts-expect-error TS(2322) FIXME: Type '{ actions: { getChannelList: () => { type: a... Remove this comment to see the full error message
          actions={actions}
          shouldShowChannelConnectedModal={shouldShowChannelConnectedModal}
          facebookGroupConnected={facebookGroupConnected}
          shouldShowChannelReconnectedModal={shouldShowChannelReconnectedModal}
          channelsRemoveResponseStatus={channelsRemoveResponseStatus}
          hasReconnectError={hasReconnectError}
          hasReconnectMissing={hasReconnectMissing}
          setHasReconnectMissing={setHasReconnectMissing}
          setHasReconnectError={setHasReconnectError}
          setIsReconnecting={setIsReconnecting}
        />

        <TitleWrapper>
          <Text type="h1">Channels</Text>
          {currentOrganization?.isOneBufferOrganization &&
          shouldShowBillingActions ? (
            <ButtonsWrapper>
              {shouldShowAddOrRemoveAction(contextAccount) && (
                // @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message
                <Button
                  type="secondary"
                  label="Add or Remove Channels"
                  onClick={
                    isOnTieredPricing
                      ? openTieredPricingModal
                      : openSubscriptionUpdate
                  }
                  disabled={!canEdit}
                />
              )}
              {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => void; label: ... Remove this comment to see the full error message */}
              <Button
                type="primary"
                onClick={
                  isChannelStorefrontEnabled
                    ? openChannelStoreFront
                    : navigateToConnectChannelsPage
                }
                label="Connect Channel"
                disabled={!canEdit}
              />
            </ButtonsWrapper>
          ) : (
            // @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => void; label: ... Remove this comment to see the full error message
            <Button
              type="primary"
              onClick={
                isChannelStorefrontEnabled
                  ? openChannelStoreFront
                  : navigateToConnectChannelsPage
              }
              label="Connect New Channel"
              disabled={!canEdit}
            />
          )}
        </TitleWrapper>

        {canEdit && currentOrganization?.isOneBufferOrganization && (
          <NoticeWrapper>
            <PricingNotice
              isPayingWithBank={isPayingWithBank}
              isOnTieredPricing={isOnTieredPricing}
            />
          </NoticeWrapper>
        )}

        {!canEdit && !loading && (
          <WarningMessageWrapper>
            <WarningMessage message="Contact an Admin in your organization to manage channels." />
          </WarningMessageWrapper>
        )}

        {currentOrganization?.billingNotifications
          ?.shouldUpgradeMobileSubscription && (
          <WarningMessageWrapper>
            {/* @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
            <Notice type="warning">
              {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
              <Text>
                You&apos;ve reached the limit on the number of channels you can
                connect with your current plan. To connect more channels, please
                upgrade your plan from within your mobile app.
              </Text>
            </Notice>
          </WarningMessageWrapper>
        )}

        {currentOrganization?.billingNotifications
          ?.shouldDowngradeMobileSubscription && (
          <WarningMessageWrapper>
            {/* @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
            <Notice type="warning">
              {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
              <Text>
                Good news! You have more channels available in your plan.
                Connect a new channel to make full use of your Buffer
                subscription. If you don’t need any more channels you can
                downgrade to a different paid plan or a free account.
              </Text>
            </Notice>
          </WarningMessageWrapper>
        )}

        {loading ? (
          <SkeletonChannels />
        ) : (
          <ChannelsListWrapper>
            {currentOrganization?.isOneBufferOrganization && canEdit ? (
              <BlockWrapperStyled>
                <Text type="p">
                  {` ${connectedChannels.length}/${channelsLimit}`} channel
                  {connectedChannels.length !== 1 ? 's' : ''} connected
                </Text>
                <ChannelsCounter
                  connectedChannels={connectedChannels.length}
                  availableChannels={channelsLimit}
                  lockedChannels={lockedChannelsCount}
                />
              </BlockWrapperStyled>
            ) : (
              <BlockWrapper margin="8px">
                <Text type="p" color="grayDark">
                  <strong>
                    {channels.length}{' '}
                    {channels.length === 1 ? 'channel' : 'channels'} connected
                  </strong>
                </Text>
              </BlockWrapper>
            )}

            <ChannelsBody
              channels={channels}
              selectChannelToRemove={selectChannelToRemove}
              setShowChannelUnlockingModal={setShowChannelUnlockingModal}
              canEdit={canEdit}
            />
          </ChannelsListWrapper>
        )}
        {selectedChannelToRemove?.name && (
          <RemoveChannelModal
            selectChannelToRemove={selectChannelToRemove}
            selectedChannel={selectedChannelToRemove}
          />
        )}
        {showChannelsUpdatedNotification && (
          <Notification
            text={'You’re all set! Your channels have been updated.'}
            onClose={(): void => {
              setShowChannelsUpdatedNotification(false)
            }}
          />
        )}
        {showChannelUnlockingModal && (
          <ChannelUnlockingModal
            setShowChannelUnlockingModal={setShowChannelUnlockingModal}
            channels={channels}
            setShowChannelsUpdatedNotification={
              setShowChannelsUpdatedNotification
            }
          />
        )}
        {hasReconnectPermissionsError && (
          <PermissionsErrorModal
            onClose={(): void => {
              setHasReconnectPermissionsError(false)
            }}
          />
        )}
      </PageLayout>
    </>
  )
}

ChannelsPage.propTypes = {
  refetch: PropTypes.func,
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'Named... Remove this comment to see the full error message
MemoizedNotifications.propTypes = {
  refetch: PropTypes.func,
  actions: PropTypes.bool,
  shouldShowChannelConnectedModal: PropTypes.bool,
  facebookGroupConnected: PropTypes.bool,
  shouldShowChannelReconnectedModal: PropTypes.bool,
  channelsRemoveResponseStatus: PropTypes.object,
  hasReconnectError: PropTypes.bool,
  hasReconnectMissing: PropTypes.bool,
  setHasReconnectMissing: PropTypes.func,
  setHasReconnectError: PropTypes.func,
  setIsReconnecting: PropTypes.func,
}

export default ChannelsPage

import styled from 'styled-components'

export const BodyContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`

export const PlanItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const MobileTextWrappper = styled.div`
  align-items: center;
  margin-top: 1.5rem;
  margin-left: 1.5rem;
`

export const ScheduledUpdateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ScheduledUpdateButtonWrapper = styled.div`
  margin-right: -82px;
`

export const ScheduledUpdateErrorWrapper = styled.div`
  margin-top: 8px;
`

import styled from 'styled-components'

export const Content = styled.footer`
  display: flex;
  flex-direction: column;
  /* for mobile */
  @media (max-width: 768px) {
    width: 100%;
  }
  width: 512px;
`

import { ChannelThirdPartyService } from '../../../../channel-connections/interfaces'
import { getChannelStateCookiesV2 } from '../../pages/Channels/utils/cookies'

import { getServiceName } from '../../utils/serviceName'

import {
  ChannelType,
  type ChannelWithCredentialsInput,
  type ConnectableChannelData,
  type ConnnectedChannelData,
  type MapDataToChannelWithCredentialsArgs,
  type ServiceOauthData,
} from './types'

export function getStateTokenFromUrl(): string | null {
  const stateToken = new URLSearchParams(window.location.search).get('state')
  return stateToken
}

export function getOauthTokenFromUrl(): string | null {
  const oauthToken = new URLSearchParams(window.location.search).get(
    'oauth_token',
  )
  return oauthToken
}

// This function handles getting the code brought back in the url from the third party redirect.
// We will use either the state or oauth_token value to compare against saved cookie value.
export function getAuthenticationTokenFromUrl(): string | null {
  const stateToken = getStateTokenFromUrl()

  // Twitter / X is the outlier which does not supply state= instead it uses oauth_token
  if (!stateToken) {
    return getOauthTokenFromUrl()
  }

  return stateToken
}

export function getOauthCodeFromUrl(): string | null {
  const oauthCodeToken = new URLSearchParams(window.location.search).get('code')

  return oauthCodeToken
}

export function getOauthCodeVerifierFromUrl(): string | null {
  const codeVerifier = new URLSearchParams(window.location.search).get(
    'oauth_verifier',
  )

  return codeVerifier
}

export function checkOauthStateTokensMatch(
  service: ChannelThirdPartyService,
): boolean {
  if (service === ChannelThirdPartyService.instagram_login_api) {
    //  For channel conversion, instagram_api_login uses the state to store
    // the channel id to convert.
    return true
  }

  const stateToken = getAuthenticationTokenFromUrl()
  const stateTokenInCookies = getChannelStateCookiesV2(getServiceName(service))
  // if (!stateTokenInCookies) {
  //   // @ts-expect-error TS(2339) FIXME: Property 'notify' does not exist on type '(apiKeyO... Remove this comment to see the full error message
  //   Bugsnag.notify(
  //     'Channel Connection: checkOauthStateTokensMatch: stateTokenInCookies was undefined. Could not complete connection process',
  //   )
  // }

  // if (stateToken !== stateTokenInCookies) {
  //   // @ts-expect-error TS(2339) FIXME: Property 'notify' does not exist on type '(apiKeyO... Remove this comment to see the full error message
  //   Bugsnag.notify(
  //     'Channel Connection: checkOauthStateTokensMatch: Could not complete connection process. State tokens did not match. Something may have gone wrong in the oauth process',
  //   )
  // }

  return stateToken === stateTokenInCookies
}

export function getTokensForService(
  service: ChannelThirdPartyService,
): ServiceOauthData | null {
  const oauthToken = getOauthTokenFromUrl()
  const oauthCodeToken = getOauthCodeFromUrl()
  const codeVerifier = getOauthCodeVerifierFromUrl()

  if (
    service === ChannelThirdPartyService.twitter &&
    codeVerifier &&
    oauthToken
  ) {
    return {
      codeVerifier,
      code: oauthToken,
    }
  }

  if (!oauthCodeToken) return null

  // Default code token for all other Single Type channels
  return {
    code: oauthCodeToken,
  }
}

export function getChannelType(
  channel: ConnectableChannelData,
  service: string,
): ChannelType {
  if (channel.type) {
    return channel.type
  }

  if (service === ChannelThirdPartyService.youtube) {
    return ChannelType.channel
  }

  const servicesRequiringBusinessChannelType = ['instagram', 'googlebusiness']
  return servicesRequiringBusinessChannelType.includes(service)
    ? ChannelType.business
    : ChannelType.profile
}

export function mapDataToChannelWithCredentials(
  args: MapDataToChannelWithCredentialsArgs,
): ChannelWithCredentialsInput {
  const { channel, account, service } = args
  const fallbackAvatarUrl =
    'https://s3.amazonaws.com/buffer-ui/Default+Avatar.png'

  const locationData = channel?.locationData
    ? {
        location: channel?.locationData?.location,
        mapsLink: channel?.locationData?.mapsLink,
        googleAccountId: channel?.locationData?.googleAccountId,
      }
    : undefined

  const channelInput: ChannelWithCredentialsInput = {
    avatar: channel.avatar || fallbackAvatarUrl,
    organizationId: account?.currentOrganization?.id,
    service,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    type: getChannelType(channel, service),
    name: channel.name,
    serviceId: channel.serviceId,
    serverUrl: channel?.serverUrl,
    locationData,
    displayName: channel?.displayName || undefined,
  }

  return channelInput
}

export function getConnectedChannelsIds(
  channels: ConnnectedChannelData[],
): string[] {
  return channels.map((channel: ConnnectedChannelData): string => channel.id)
}

export function isNewConnection(channels: ConnnectedChannelData[]): boolean {
  return (
    channels.filter((channel: ConnnectedChannelData) => channel.isNew).length >
    0
  )
}

import { ChannelThirdPartyService } from '../../../channel-connections/interfaces'

enum InstagramAuthMethod {
  instagramLogin = 'instagramLogin',
  facebookLogin = 'facebookLogin',
}

type apiVersion = {
  apiVersion: InstagramAuthMethod
}

export function setInstagramMetadata(
  serviceName: ChannelThirdPartyService,
): apiVersion | undefined {
  switch (serviceName) {
    case ChannelThirdPartyService.instagram_login_api:
      return {
        apiVersion: InstagramAuthMethod.instagramLogin,
      }
    case ChannelThirdPartyService.instagram:
      return {
        apiVersion: InstagramAuthMethod.facebookLogin,
      }
  }
}

import React from 'react'
import { Avatar, DropdownMenu, Text, Button } from '@bufferapp/ui'
import { grayDark } from '@bufferapp/ui/style/colors'
import MoreIcon from '@bufferapp/ui/Icon/Icons/More'

import StatusSection from '../StatusSection'

import { TextStyled, ChannelRow, ButtonWrapper, AvatarWrapper } from './styles'

import { fallbackAvatarUrl } from '../../ChannelsPage/ChannelsPage'
import { START_PAGE_URL } from '../../../../utils/redirect'
import type { SelectChannelToRemoveProps } from './types'

export const PageCardRow = ({
  avatar,
  id,
  isLocked,
  name,
  service,
  type,
  selectChannelToRemove,
  descriptor,
}: {
  avatar: string
  id: string
  type: string
  isLocked: boolean
  name: string
  service: string
  selectChannelToRemove: (arg: SelectChannelToRemoveProps) => void
  descriptor: string
}): JSX.Element => {
  const redirectToSettings = (): void =>
    window.location.replace(`${START_PAGE_URL}${id}/settings`)
  const redirectToStartPage = (): void =>
    window.location.replace(`${START_PAGE_URL}${id}`)

  const handleUnpublishChannel = [
    {
      id: '2',
      title: 'Unpublish Channel',
      onItemClick: redirectToSettings,
    },
  ]

  const customDropdownMenuItems = [
    {
      id: '1',
      title: 'Edit in Start Page',
      onItemClick: redirectToStartPage,
    },
    ...(!isLocked ? handleUnpublishChannel : []),
    {
      id: '3',
      title: 'Delete Channel',
      onItemClick: (): void => {
        selectChannelToRemove({
          type,
          id,
          name,
          avatar,
          service,
          descriptor,
        })
      },
    },
  ]

  return (
    <ChannelRow data-testid={`cardrow-${id}`} isLocked={isLocked}>
      <AvatarWrapper>
        <Avatar
          src={avatar}
          alt={name}
          network={service}
          fallbackUrl={fallbackAvatarUrl}
        />
      </AvatarWrapper>
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <TextStyled type="p" title={name}>
          {name}
        </TextStyled>
        <Text type="p">Start Page</Text>
      </div>
      {isLocked && (
        <ButtonWrapper>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => void; label: ... Remove this comment to see the full error message */}
          <Button
            type="secondary"
            onClick={redirectToStartPage}
            label="Publish to Connect"
          />
        </ButtonWrapper>
      )}

      {!isLocked && <StatusSection />}

      <div>
        <DropdownMenu
          // @ts-expect-error TS(2322) FIXME: Type '{ ariaLabel: string; ariaLabelPopup: string;... Remove this comment to see the full error message
          ariaLabel="Channel Menu"
          ariaLabelPopup="Channel Menu"
          menubarItem={<MoreIcon color={grayDark} />}
          items={customDropdownMenuItems}
          xPosition="right"
        />
      </div>
    </ChannelRow>
  )
}

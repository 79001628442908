import gql from 'graphql-tag'

export const GET_ACCOUNT = gql`
  query Account {
    account {
      id
      email
      name
      createdAt
      featureFlips
      isImpersonation
      shouldShowEmailVerificationCommunication
      hasVerifiedEmail
      currentOrganization {
        id
        isOneBufferOrganization
        name
        canEdit
        channelRecommendations
        isBetaEnabled
        privileges {
          canManageBilling
          canManageChannels
        }
        canAddPaymentDetails
        role
        createdAt
        channelLimit
        featureFlips
        isOneBufferOrganization
        canMigrateToBufferViaHub {
          canMigrate
          reasons
          subscriptionDiscount {
            type
            duration
            value
          }
        }
        dismissedObjects {
          id
        }
        commonTrackingProperties {
          atEventBillingState
          atEventBillingPlan
          atEventBillingCycle
          atEventBillingGateway
          atEventIsNewBuffer
          atEventChannelQty
        }
        entitlements
        limits {
          channels
        }
        billingNotifications {
          shouldUpgradeMobileSubscription
          shouldDowngradeMobileSubscription
        }
        members {
          totalCount
        }
        channels {
          id
          isLocked
          service
        }
        billingDetails {
          allStripeAccounts {
            id
            name
            created
            billing {
              card {
                brand
                last4
                expMonth
                expYear
              }
            }
            customerIsPausedWithCoupon
            delinquent
            hasMoreInvoices
            products {
              analyze {
                cycle
                cycleEndDate
                discount {
                  type
                  discount
                  name
                }
                isActiveTrial
                planName
                price
                product
                subscriptionIsCanceled
                subscriptionCanceledDate
                subscriptionEndDate
                subscriptionId
                subscriptionIsPaused
                trialEndDate
                type
              }
              analyzeTrial {
                cycle
                cycleEndDate
                discount {
                  type
                  discount
                  name
                }
                isActiveTrial
                planName
                price
                product
                subscriptionIsCanceled
                subscriptionCanceledDate
                subscriptionEndDate
                subscriptionId
                subscriptionIsPaused
                trialEndDate
                type
              }
              publish {
                cycle
                cycleEndDate
                discount {
                  type
                  discount
                  name
                }
                isActiveTrial
                planName
                price
                product
                subscriptionIsCanceled
                subscriptionCanceledDate
                subscriptionEndDate
                subscriptionId
                subscriptionIsPaused
                trialEndDate
                type
              }
              publishTrial {
                cycle
                cycleEndDate
                discount {
                  type
                  discount
                  name
                }
                isActiveTrial
                planName
                price
                product
                subscriptionIsCanceled
                subscriptionCanceledDate
                subscriptionEndDate
                subscriptionId
                subscriptionIsPaused
                trialEndDate
                type
              }
            }
            invoices {
              id
              amount
              date
              downloadUrl
              status
            }
          }
        }
        billing {
          isSubscriptionTaxEnabled
          taxDetails {
            automaticTax
            location {
              country
              state
              source
            }
            ipAddress
          }
          ... on OBBilling {
            address {
              city
              country
              line1
              line2
              state
              postalCode
            }
            taxExempt
            taxIds {
              country
              created
              type
              value
              verification {
                status
                verifiedAddress
                verifiedName
              }
            }
            upcomingInvoiceAmount
            upcomingInvoice {
              amountWithoutTax
              amountTax
              currency
            }
            canStartTrial
            balance
            discount {
              gatewayId
              name
              type
              duration
              value
              durationInMonths
            }
            gateway {
              gatewayType
              gatewayPlatform
              gatewayId
            }
            isAutomaticTaxEnabled
            paymentDetails {
              hasPaymentDetails
              creditCard {
                brand
                last4
                expMonth
                expYear
              }
              paymentMethod
            }
            pricingTiers {
              flatFee
              unitPrice
              upperBound
            }
            subscription {
              periodEnd
              isCanceledAtPeriodEnd
              isPaymentPastDue
              canceledAt
              trial {
                isActive
                remainingDays
                startDate
                endDate
              }
              plan {
                id
                name
                interval
                limits {
                  channels
                  members
                }
                prices {
                  baseMonthlyPrice
                  basePlanPrice
                  monthlyFlatFee
                  monthlyChannelPrice
                  channelTierUnit
                }
              }
              scheduledUpdate {
                newPlan
                newInterval
                newQuantity
                effectiveDate
              }
            }
          }
          ... on MPBilling {
            gateway {
              gatewayType
              gatewayPlatform
              gatewayId
            }
            address {
              city
              country
              line1
              line2
              state
              postalCode
            }
            taxExempt
            taxIds {
              country
              created
              type
              value
              verification {
                status
                verifiedAddress
                verifiedName
              }
            }
            upcomingInvoiceAmount
            upcomingInvoice {
              amountWithoutTax
              amountTax
              currency
            }
            canAccessPublishing
            canAccessAnalytics
            billingRedirectUrl
            balance
            discount {
              gatewayId
              name
              type
              duration
              value
              durationInMonths
            }
            subscriptions {
              plan
              product
            }
            paymentDetails {
              hasPaymentDetails
              paymentMethod
            }
          }
        }
      }
      channels {
        id
        name
        service
        avatar
        isDisconnected
        isLocked
        organizationId
      }
      organizations {
        id
        name
      }
      products {
        name
        userId
      }
    }
  }
`

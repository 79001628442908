export const Services = {
  STARTPAGE: 'startPage',
  PINTEREST: 'pinterest',
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedin',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  TIKTOK: 'tiktok',
  GOOGLE_BUSINESS_PROFILE: 'google business profile',
  MASTODON: 'mastodon',
  YOUTUBE: 'youtube',
  THREADS: 'threads',
  BLUESKY: 'bluesky',
}

export const FormattedServiceNames = {
  YOUTUBE: 'YouTube',
  TWITTER: 'Twitter / X',
}

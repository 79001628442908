import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;

  /* non flex for mobile */
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
export const ContentWrapper = styled.div`
  width: 100%;
  padding: 1rem 1rem 7rem;
  background-color: '#fff';
  display: flex;
  flex-direction: column;
  align-items: center;

  /* for mobile */
  @media (max-width: 768px) {
    padding: 1rem;
  }
`

import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import { CreditCardForm } from './form'
import { env } from '~/env'

const stripePromise = loadStripe(env.VITE_STRIPE_PUBLISHABLE)

export const StripeCreditCardForm = (props: any): JSX.Element => {
  return (
    <Elements stripe={stripePromise}>
      <CreditCardForm {...props} />
    </Elements>
  )
}

import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  img {
    width: 34px;
    height: 34px;
  }

  @media (max-width: 768px) {
    align-self: flex-end;
  }
`

export const QuoteWrapper = styled.div`
  padding: 8px;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.04);
  margin-right: 24px;
  position: relative;

  p {
    font-size: 13px;
  }

  .tail {
    &:after {
      content: '';
      display: block;

      position: absolute;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 17px solid white;
      right: -15px;
      top: 17px;
      box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.04);
    }
  }
`

import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { ChannelThirdPartyService } from '../../../../../channel-connections/interfaces'

// This specific error occurs either when:
// - a user attempts to connect a personal Instagram account, but decides to
// not convert to a professional account OR
// - a user denies permissions to their Instagram account

export function isInstagramUserActionError({
  service,
  organizationId = '',
}: {
  service: ChannelThirdPartyService
  organizationId: string
}): boolean {
  const isInstagramLogin =
    service === ChannelThirdPartyService.instagram_login_api

  if (!isInstagramLogin) {
    return false
  }

  const queryParams = new URLSearchParams(window.location.search)
  const errorDescription = queryParams.get('error_description')

  const userCanceledChannelConversion =
    errorDescription === 'The user cancelled pro account conversion.'

  if (userCanceledChannelConversion) {
    BufferTracker.channelConversionCanceledByUser({
      clientName: 'publishWeb',
      organizationId,
      channel: 'instagram',
    })
  }

  const userDeniedPermissions =
    errorDescription === 'The user denied your request.'

  if (userDeniedPermissions) {
    BufferTracker.channelConnectionAborted({
      reason: 'user canceled pro account conversion',
      product: 'account',
      clientName: 'core',
      organizationId,
      channel: 'instagram',
    })
  }

  return (
    isInstagramLogin && (userCanceledChannelConversion || userDeniedPermissions)
  )
}

export function getCustomChannelIdToConvertFromParams(): string | undefined {
  const params = new URLSearchParams(document.location.search)
  const state = params.get('state')
  const channelIdToConvert = state?.split('custom_channel_id_to_convert_')[1]

  return channelIdToConvert || undefined
}

const IS_WITH_PROD = import.meta.env.MODE === 'dev-with-prod'
const IS_WITH_STAGING = import.meta.env.MODE === 'dev-with-staging'

type EnvName = 'production' | 'staging' | 'development' | 'test'

function getEnvFromHostname(): EnvName {
  if (window.location.hostname.includes('.dev')) {
    return 'staging'
  }
  if (window.location.hostname.includes('.local')) {
    return 'development'
  }
  return 'production'
}

export type Env = {
  VITE_NODE_ENV: EnvName
  VITE_COMMIT_HASH: string
  VITE_SC_ATTR: string
  VITE_API_GATEWAY_URL: string
  VITE_NAVIGATOR_URL: string
  VITE_BUGSNAG_FRONTEND_KEY: string
  VITE_SEGMENT_KEY_CLIENT: string
  VITE_STRIPE_PUBLISHABLE: string
  VITE_FB_APP_ID: string
  VITE_LINKEDIN_CLIENT_ID: string
  VITE_TIKTOK_CLIENT_ID: string
  VITE_X_BUFFER_CLIENT_ID: string
  VITE_CUSTOMERIO_SITE_ID: string
  VITE_CUSTOMERIO_API_KEY: string
}

const COMMON_VARS: Pick<
  Env,
  'VITE_COMMIT_HASH' | 'VITE_SC_ATTR' | 'VITE_X_BUFFER_CLIENT_ID'
> = {
  VITE_COMMIT_HASH: `${import.meta.env.VITE_COMMIT_HASH}`,
  VITE_SC_ATTR: 'account-styles',
  VITE_X_BUFFER_CLIENT_ID: 'webapp-account',
}

const PRODUCTION_VARS: Env = {
  ...COMMON_VARS,
  VITE_NODE_ENV: 'production',
  VITE_API_GATEWAY_URL: 'https://graph.buffer.com',
  VITE_NAVIGATOR_URL: 'https://components.buffer.com/navigator.js',
  VITE_BUGSNAG_FRONTEND_KEY: '34dc7834c1bea7ec57f9c365a12eb779',
  VITE_SEGMENT_KEY_CLIENT: 'OhmZAyohIduyqmRaNXHDg3QwC6vLjgEK',
  VITE_STRIPE_PUBLISHABLE: 'pk_qOmHaWApLCX5OoeWKQ3NrArhoyWEi',
  VITE_FB_APP_ID: '103667826405103',
  VITE_LINKEDIN_CLIENT_ID: '77fa6vv5l9hkmd',
  VITE_TIKTOK_CLIENT_ID: '7103819247981182978',
  VITE_CUSTOMERIO_SITE_ID: 'f902d427753d24b6e6df',
  VITE_CUSTOMERIO_API_KEY: 'f902d427753d24b6e6df:3ab31a41d059272394c8',
}

const STAGING_VARS: Env = {
  ...COMMON_VARS,
  VITE_NODE_ENV: 'staging',
  VITE_API_GATEWAY_URL: 'https://graph.dev.buffer.com',
  VITE_NAVIGATOR_URL: 'https://components.buffer.com/navigator-staging.js',
  VITE_BUGSNAG_FRONTEND_KEY: '34dc7834c1bea7ec57f9c365a12eb779',
  VITE_SEGMENT_KEY_CLIENT: 'e2Eyn5oQrUE0SmKvZvhvPeP21rc9z8EV',
  VITE_STRIPE_PUBLISHABLE: 'pk_dGKqAIFsUQonSYGPBM9Rek71IHOcL',
  VITE_FB_APP_ID: '690085224430024',
  VITE_LINKEDIN_CLIENT_ID: '77wd15t1hg64u5',
  VITE_TIKTOK_CLIENT_ID: '7102477306047774721',
  VITE_CUSTOMERIO_SITE_ID: '7c16d71a254f8ac07907',
  VITE_CUSTOMERIO_API_KEY: '7c16d71a254f8ac07907:9c61e8f8717a504dba4e',
}

const DEVELOPMENT_VARS: Env = {
  ...STAGING_VARS,
  ...COMMON_VARS,
  VITE_NODE_ENV: 'development',
  VITE_API_GATEWAY_URL: 'https://graph.local.buffer.com',
  VITE_NAVIGATOR_URL: 'https://components.buffer.com/navigator.js',
  // VITE_NAVIGATOR_URL:
  //   'https://appshell.local.buffer.com:4713/navigator.iife.js',
  VITE_BUGSNAG_FRONTEND_KEY: '34dc7834c1bea7ec57f9c365a12eb779',
  VITE_SEGMENT_KEY_CLIENT: 'e2Eyn5oQrUE0SmKvZvhvPeP21rc9z8EV',
  VITE_STRIPE_PUBLISHABLE: 'pk_dGKqAIFsUQonSYGPBM9Rek71IHOcL',
  VITE_FB_APP_ID: '690085224430024',
  VITE_LINKEDIN_CLIENT_ID: '77wd15t1hg64u5',
  VITE_TIKTOK_CLIENT_ID: '7102477306047774721',
  VITE_CUSTOMERIO_SITE_ID: '82d38857f6dae390778e',
  VITE_CUSTOMERIO_API_KEY: '82d38857f6dae390778e:8e51af7d0a8f1d08e737',
}

function selectEnv(): Env {
  const envName = getEnvFromHostname()

  if (IS_WITH_PROD) {
    return {
      ...PRODUCTION_VARS,
      VITE_NODE_ENV: 'development',
    }
  }
  if (IS_WITH_STAGING) {
    return {
      ...STAGING_VARS,
      VITE_NODE_ENV: 'development',
    }
  }
  if (envName === 'development') {
    return DEVELOPMENT_VARS
  }
  if (envName === 'staging') {
    return STAGING_VARS
  }

  return PRODUCTION_VARS
}

export const env = selectEnv()

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { BlockWrapper } from '../../../account-components'
import CardDetails from '../../../credit-card'
import { Notification, Notice, Loader, Text } from '@bufferapp/ui'
import InfoIcon from '@bufferapp/ui/Icon/Icons/Info'

import BillingDetails from '../BillingDetails'
import ChangePlan from '../ChangePlan'
import InvoicesAndReceipts from '../../../web/src/pages/Billing/components/InvoicesAndReceipts'
import BalanceAndDiscount from '../../../web/src/pages/Billing/components/PlansCard/components/BalanceAndDiscount'

import { Container, LoaderWrapper, Link, LinkWrapper } from './styles'
import { getProductUrl } from '../../../shared-utils'
import BillingInformation from '../../../web/src/pages/Billing/components/BillingInformation'
import {
  hasValidTaxAddress,
  isCustomerLocatedInTaxArea,
} from '../../../web/src/pages/Billing/components/PlansCard/components/TaxMessaging/utils'
import TaxMessaging from '../../../web/src/pages/Billing/components/PlansCard/components/TaxMessaging/TaxMessaging'
import { CopyWrapper } from '../../../web/src/pages/Billing/components/PlansCard/components/PlanBillingNotice/styles'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import useGetPortalSession from '../../../web/src/pages/Billing/components/BillingInformation/hooks/useGetPortalSession'
import { HelpScoutBeacon } from '../../../helpScoutBeacon'
import { isPaymentMethodBank } from '~/legacy/web/src/utils/isPaymentMethodBank'

const CentralizedBilling = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'changePlan' implicitly has an 'an... Remove this comment to see the full error message
  changePlan,
  // @ts-expect-error TS(7031) FIXME: Binding element 'changePlanModalView' implicitly h... Remove this comment to see the full error message
  changePlanModalView,
  // @ts-expect-error TS(7031) FIXME: Binding element 'closeChangePlanModal' implicitly ... Remove this comment to see the full error message
  closeChangePlanModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'closeChangePlanNotification' impl... Remove this comment to see the full error message
  closeChangePlanNotification,
  // @ts-expect-error TS(7031) FIXME: Binding element 'creditCard' implicitly has an 'an... Remove this comment to see the full error message
  creditCard,
  // @ts-expect-error TS(7031) FIXME: Binding element 'currentCycle' implicitly has an '... Remove this comment to see the full error message
  currentCycle,
  // @ts-expect-error TS(7031) FIXME: Binding element 'currentOrganization' implicitly h... Remove this comment to see the full error message
  currentOrganization,
  // @ts-expect-error TS(7031) FIXME: Binding element 'currentPlan' implicitly has an 'a... Remove this comment to see the full error message
  currentPlan,
  // @ts-expect-error TS(7031) FIXME: Binding element 'fetchBillingDetailsSuccess' impli... Remove this comment to see the full error message
  fetchBillingDetailsSuccess,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isChangingPlan' implicitly has an... Remove this comment to see the full error message
  isChangingPlan,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isSubmittingCreditCard' implicitl... Remove this comment to see the full error message
  isSubmittingCreditCard,
  // @ts-expect-error TS(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
  loading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'planForReview' implicitly has an ... Remove this comment to see the full error message
  planForReview,
  // @ts-expect-error TS(7031) FIXME: Binding element 'resetPlanForReview' implicitly ha... Remove this comment to see the full error message
  resetPlanForReview,
  // @ts-expect-error TS(7031) FIXME: Binding element 'saveCardDetails' implicitly has a... Remove this comment to see the full error message
  saveCardDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectPlanForReview' implicitly h... Remove this comment to see the full error message
  selectPlanForReview,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedOrganizationDetails' impl... Remove this comment to see the full error message
  selectedOrganizationDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'showChangePlanModal' implicitly h... Remove this comment to see the full error message
  showChangePlanModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'showChangePlanNotification' impli... Remove this comment to see the full error message
  showChangePlanNotification,
  // @ts-expect-error TS(7031) FIXME: Binding element 'toggleChangePlanCycle' implicitly... Remove this comment to see the full error message
  toggleChangePlanCycle,
  // @ts-expect-error TS(7031) FIXME: Binding element 'updateChangePlanModalView' implic... Remove this comment to see the full error message
  updateChangePlanModalView,
  // @ts-expect-error TS(7031) FIXME: Binding element 'openCancelPlanModal' implicitly h... Remove this comment to see the full error message
  openCancelPlanModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'account' implicitly h... Remove this comment to see the full error message
  account,
}): JSX.Element => {
  if (loading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { openBillingPortal } = useGetPortalSession()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [productName] = useState('')

  const migrationURL = getProductUrl('account')
    ? `${getProductUrl('account')}/new-plans/?cta=billing-migration-cta`
    : 'https://account.buffer.com/new-plans/?cta=billing-migration-cta'

  const isStripeUser =
    currentOrganization &&
    currentOrganization.billingDetails &&
    currentOrganization.billingDetails.allStripeAccounts &&
    currentOrganization.billingDetails.allStripeAccounts.length > 0

  const canEdit = currentOrganization?.canEdit

  // These values are all needed for PlanBillingNotice
  // which renders tax collection messaging
  const isSubscriptionTaxEnabled = Boolean(
    account?.currentOrganization?.billing?.isSubscriptionTaxEnabled,
  )
  const isCustomerTaxExempt =
    account?.currentOrganization?.billing?.taxExempt === 'exempt'

  const customerTaxDetails = account?.currentOrganization?.billing?.taxDetails

  const isPayingCustomerWithValidTaxAddress =
    hasValidTaxAddress(customerTaxDetails)
  const customerTaxStatus = customerTaxDetails?.automaticTax
  const isInTaxableLocation = isCustomerLocatedInTaxArea(customerTaxStatus)

  // We only want to display the Tax Notice to Existing Customers
  // Who do not have subscription enabled tax collection yet and are not tax exempt and are paying customers
  const shouldDisplayTaxMessaging =
    !isCustomerTaxExempt && !isSubscriptionTaxEnabled

  const handleUpdateAddressCTA = (): void => {
    BufferTracker.billingPortalSessionCreated({
      organizationId: account?.currentOrganization?.id,
      customerId: account?.currentOrganization?.billing?.gateway?.gatewayId,
      ctaApp: 'account',
      ctaView: 'billing',
      ctaLocation: 'plansBillingCard',
      ctaButton: 'pleaseAddYourAddress',
      ctaVersion: '1',
    })

    openBillingPortal(true)
  }

  const isPayingWithBank = isPaymentMethodBank(account)

  return (
    <React.Fragment>
      {showChangePlanNotification && (
        <Notification
          text={"You've successfully updated your plan."}
          onClose={closeChangePlanNotification}
        />
      )}
      <BlockWrapper margin="8px">
        <Container>
          {/* @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
          <Notice type="warning" disableAnimation>
            <CopyWrapper>
              <Text type="p">
                <strong>You are on a legacy plan.</strong>{' '}
                <a
                  data-testid="account-billing-notice-updatenow"
                  href={migrationURL}
                  className="legacy-upgrade-link"
                >
                  Update now
                </a>{' '}
                to unlock all the latest features.
              </Text>
              {shouldDisplayTaxMessaging && (
                <TaxMessaging
                  hasValidTaxAddress={isPayingCustomerWithValidTaxAddress}
                  isInTaxableLocation={isInTaxableLocation}
                  onUpdateAddressClick={handleUpdateAddressCTA}
                />
              )}
            </CopyWrapper>
          </Notice>
        </Container>
      </BlockWrapper>
      <BlockWrapper margin="0px">
        <BalanceAndDiscount
          balance={currentOrganization.billing?.balance}
          discount={currentOrganization.billing?.discount}
        />
      </BlockWrapper>
      {!canEdit && (
        <BlockWrapper>
          {/* @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
          <Notice type="note">
            <InfoIcon className="note-icon" />
            {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
            <Text>
              Contact an Admin in your organization to view and edit billing
              details.
            </Text>
          </Notice>
        </BlockWrapper>
      )}
      {isPayingWithBank && (
        <BlockWrapper>
          <Notice type="note" className={'bankPaymentNote'}>
            <InfoIcon className="note-icon" />
            <Text type="p">
              To make changes to your plan, please contact support at{' '}
              <a href="mailto:hello@buffer.com">hello@buffer.com</a>.
            </Text>
          </Notice>
        </BlockWrapper>
      )}
      {canEdit && (
        <BillingDetails
          subscriptions={
            selectedOrganizationDetails && selectedOrganizationDetails.products
          }
          selectedOrganizationDetails={selectedOrganizationDetails}
          openCancelPlanModal={openCancelPlanModal}
          isPayingWithBank={isPayingWithBank}
        />
      )}
      <BlockWrapper>
        <LinkWrapper>
          <span>Need help?</span>
          <Link
            onClick={(): void => {
              HelpScoutBeacon.displayBeaconChatOptions()
            }}
          >
            Talk to our support team
          </Link>{' '}
        </LinkWrapper>
      </BlockWrapper>
      {canEdit && isStripeUser && (
        <BlockWrapper>
          <CardDetails
            cardDetails={selectedOrganizationDetails?.billing?.card}
          />
        </BlockWrapper>
      )}
      {canEdit && isStripeUser && (
        <BillingInformation
          address={currentOrganization.billing?.address}
          taxIds={currentOrganization.billing?.taxIds}
          taxExempt={currentOrganization.billing?.taxExempt}
        />
      )}
      {canEdit && isStripeUser && (
        <BlockWrapper>
          <InvoicesAndReceipts />
        </BlockWrapper>
      )}
      {canEdit && showChangePlanModal && (
        <ChangePlan
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          currentPlan={currentPlan}
          closeChangePlanModal={closeChangePlanModal}
          confirmChangePlan={changePlan}
          creditCard={creditCard}
          currentView={changePlanModalView}
          fetchBillingDetailsSuccess={fetchBillingDetailsSuccess}
          handleResetPlanForReview={resetPlanForReview}
          hasCard={
            selectedOrganizationDetails.billing &&
            selectedOrganizationDetails.billing.card
          }
          toggleCycle={toggleChangePlanCycle}
          cycle={currentCycle}
          isChangingPlan={isChangingPlan}
          isSubmittingCreditCard={isSubmittingCreditCard}
          planForReview={planForReview}
          product={productName}
          saveCardDetails={saveCardDetails}
          selectPlanForReview={selectPlanForReview}
          stripeCustomerId={selectedOrganizationDetails.id}
          updateChangePlanModalView={updateChangePlanModalView}
        />
      )}
    </React.Fragment>
  )
}

CentralizedBilling.propTypes = {
  changePlan: PropTypes.func,
  changePlanModalView: PropTypes.string,
  closeChangePlanModal: PropTypes.func,
  closeChangePlanNotification: PropTypes.func,
  creditCard: PropTypes.object,
  currentCycle: PropTypes.string,
  currentOrganization: PropTypes.object,
  currentPlan: PropTypes.string,
  fetchBillingDetailsSuccess: PropTypes.bool,
  isChangingPlan: PropTypes.bool,
  isSubmittingCreditCard: PropTypes.bool,
  loading: PropTypes.bool,
  planForReview: PropTypes.object,
  resetPlanForReview: PropTypes.func,
  saveCardDetails: PropTypes.func,
  selectPlanForReview: PropTypes.func,
  selectedOrganizationDetails: PropTypes.object,
  showChangePlanModal: PropTypes.bool,
  showChangePlanNotification: PropTypes.bool,
  toggleChangePlanCycle: PropTypes.func,
  updateChangePlanModalView: PropTypes.func,
  openCancelPlanModal: PropTypes.func,
  openChangePlanModal: PropTypes.func,
  account: PropTypes.object,
}

CentralizedBilling.defaultProps = {
  selectedOrganizationDetails: {},
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'an... Remove this comment to see the full error message
const CentralizedBillingWrapper = (props): JSX.Element => (
  <CentralizedBilling {...props} />
)

export default CentralizedBillingWrapper

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Input, Text, Modal, Button } from '@bufferapp/ui'
import { toTitleCase } from '../../../shared-utils'

import {
  Wrapper,
  ModalContent,
  ModalFooter,
  Title,
  Form,
  StyledReason,
  StyledRadio,
  CheckMark,
} from './styles'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { useAccount } from '../../../web/src/context/Account'

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const Reason = ({ children, value, onChange }) => (
  <StyledReason>
    <Text type="label" htmlFor={`churnOption${value}`}>
      <StyledRadio
        onChange={onChange}
        id={`churnOption${value}`}
        name="churnOptions"
        value={value}
      />
      <CheckMark /> {children}
    </Text>
  </StyledReason>
)

// NOTE: As part of https://linear.app/buffer/issue/CORE-1660/remove-deprecatedchurnsurveymodal, we are moving the ChurnSurvey modal to the appshell
// This component can still be used but will soon be replaced by the appshell version
const DeprecatedChurnSurveyModal = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'submit' implicitly has an 'any'... Remove this comment to see the full error message
  submit,
  // @ts-expect-error TS(7031) FIXME: Binding element 'dismiss' implicitly has an 'any'... Remove this comment to see the full error message
  dismiss,
  // @ts-expect-error TS(7031) FIXME: Binding element 'product' implicitly has an 'any'... Remove this comment to see the full error message
  product,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isOnTrial' implicitly has an 'any'... Remove this comment to see the full error message
  isOnTrial,
}): JSX.Element => {
  const [reason, setReason] = useState(-1)
  const [details, setDetails] = useState('')
  const [isValid, setIsValid] = useState(false)

  // @ts-expect-error TS(7006) FIXME: Parameter 'ev' implicitly has an 'any' type.
  const onReasonChange = (ev) => {
    setReason(ev.target.value)
    setIsValid(true)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'ev' implicitly has an 'any' type.
  const onDetailsChange = (ev) => {
    setDetails(ev.target.value)
  }

  const account = useAccount()
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null
  const organizationId = account?.currentOrganization?.id || ''
  useEffect(() => {
    if (account?.currentOrganization) {
      BufferTracker.churnSurveyViewed({
        organizationId,
        ...commonTrackingProperties,
      })
    }
  }, [account?.currentOrganization, organizationId, commonTrackingProperties])

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; action: {}; }' is not a... Remove this comment to see the full error message
    <Modal action={{}}>
      <Wrapper>
        <Title>
          <h3>We are sad to see you go!</h3>
        </Title>
        <ModalContent>
          {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */}
          <Text>
            We would love to understand why so we can make{' '}
            {toTitleCase(product)} better.
            <br />
            <b>Would you mind sharing the reason with us?</b>
            <br />
            <br />
            <Text type="label">I’m leaving because:</Text>
          </Text>
          <Form>
            <fieldset>
              <Reason
                // @ts-expect-error TS(7006) FIXME: Parameter 'ev' implicitly has an 'any' type.
                onChange={(ev) => {
                  onReasonChange(ev)
                }}
                value={'extenuating-circumstances'}
              >
                Extenuating Circumstances (e.g. COVID-19, illness, budget cuts,
                etc.) <br />{' '}
              </Reason>
              <Reason
                // @ts-expect-error TS(7006) FIXME: Parameter 'ev' implicitly has an 'any' type.
                onChange={(ev) => {
                  onReasonChange(ev)
                }}
                value={'not-using-anymore'}
              >
                I don&apos;t use the product anymore <br />{' '}
              </Reason>
              <Reason
                // @ts-expect-error TS(7006) FIXME: Parameter 'ev' implicitly has an 'any' type.
                onChange={(ev) => {
                  onReasonChange(ev)
                }}
                value={'too-expensive'}
              >
                Too expensive <br />
              </Reason>
              <Reason
                // @ts-expect-error TS(7006) FIXME: Parameter 'ev' implicitly has an 'any' type.
                onChange={(ev) => {
                  onReasonChange(ev)
                }}
                value={'missing-features'}
              >
                Missing features <br />
              </Reason>
              <Reason
                // @ts-expect-error TS(7006) FIXME: Parameter 'ev' implicitly has an 'any' type.
                onChange={(ev) => {
                  onReasonChange(ev)
                }}
                value={'bugs'}
              >
                Bugs or usability issues <br />
              </Reason>
              <Reason
                // @ts-expect-error TS(7006) FIXME: Parameter 'ev' implicitly has an 'any' type.
                onChange={(ev) => {
                  onReasonChange(ev)
                }}
                value={'others'}
              >
                Other <br />
              </Reason>
            </fieldset>
            <Input
              // @ts-expect-error TS(2322) FIXME: Type '{ onChange: (ev: any) => void; disabled: boo... Remove this comment to see the full error message
              onChange={(ev) => {
                onDetailsChange(ev)
              }}
              disabled={!isValid}
              name="details"
              label="Any other details:"
            />
          </Form>
        </ModalContent>
        <ModalFooter>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
          <Button
            type="text"
            label="No, Do Not Cancel"
            onClick={(): void => {
              dismiss()
              BufferTracker.churnSurveyCTAClicked({
                organizationId,
                ctaName: 'doNotCancel_churnSurvey',
                ...commonTrackingProperties,
              })
            }}
          />
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
          <Button
            type="primary"
            label="Continue"
            onClick={(): void => {
              submit({
                reason,
                details,
                product,
                isOnTrial,
              })
              BufferTracker.churnSurveyCTAClicked({
                organizationId,
                ctaName: 'continue_churnSurvey',
                ...commonTrackingProperties,
              })
            }}
            disabled={!isValid}
          />
        </ModalFooter>
      </Wrapper>
    </Modal>
  )
}

DeprecatedChurnSurveyModal.propTypes = {
  submit: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
  product: PropTypes.string.isRequired,
  isOnTrial: PropTypes.bool,
}

DeprecatedChurnSurveyModal.defaultProps = {
  isOnTrial: false,
}

export default DeprecatedChurnSurveyModal

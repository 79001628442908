import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'
import { getCommonTrackingPropertiesFromAccount } from '../../../../tracking/utils'
import type { ChannelThirdPartyService } from '../../../../channel-connections/interfaces'
import type { Account } from '../../pages/Channels/auth-flows/shared/SelectChannelList/utils'
import {
  getCurrentPlanNameFromAccount,
  isAtPlanLimit,
} from '../../../../shared-utils'
import { setApiVersion } from './utils'

export function trackProfileSelectorViewed({
  account,
  serviceName,
}: {
  account: Account
  serviceName: ChannelThirdPartyService
}): void {
  const commonTrackingProperties =
    getCommonTrackingPropertiesFromAccount(account)
  const organizationId = account?.currentOrganization?.id || ''
  const currentChannelQuantity = account?.channels?.length
  const isAtChannelLimit = isAtPlanLimit(account)

  return BufferTracker.channelConnectionProfileSelectorViewed({
    organizationId,
    clientName: Client.PublishWeb,
    channel: serviceName,
    currentChannelQuantity,
    product: 'account',
    isAtPlanLimit: isAtChannelLimit,
    ...commonTrackingProperties,
  })
}

export function trackChannelConnectionAborted({
  account,
  serviceName,
}: {
  account: Account
  serviceName: ChannelThirdPartyService
}): void {
  const organizationId = account?.currentOrganization?.id || ''

  return BufferTracker.channelConnectionAborted({
    organizationId,
    channel: serviceName,
    product: 'account',
    clientName: Client.PublishWeb,
  })
}

export function trackChannelConnectionAttempted({
  account,
  serviceName,
}: {
  account: Account
  serviceName: ChannelThirdPartyService
}): void {
  const organizationId = account?.currentOrganization?.id || ''
  const isAtChannelLimit = isAtPlanLimit(account)
  const planName = getCurrentPlanNameFromAccount(account)

  BufferTracker.channelConnectionAttempted({
    organizationId,
    channel: serviceName,
    product: 'account',
    clientName: Client.PublishWeb,
    planNameAtTimeOfAttempt: planName,
    isAtPlanLimit: isAtChannelLimit,
    apiVersion: setApiVersion(serviceName),
  })
}

import React from 'react'

import { WarningMessage } from '../../../../../../account-components'

import * as Styles from './styles'

export const ContactAdminMessage = (): JSX.Element => (
  <Styles.WarningMessageWrapper>
    <WarningMessage message="Contact an Admin in your organization to manage channels." />
  </Styles.WarningMessageWrapper>
)

import React from 'react'
import { Button } from '@buffer-mono/popcorn'

const Reconnect = ({
  text,
  canEdit,
  onRefreshConnectionClick,
}: {
  text?: string
  canEdit?: boolean
  onRefreshConnectionClick?: () => void
}): JSX.Element => (
  <Button
    variant="primary"
    size="small"
    onClick={onRefreshConnectionClick}
    disabled={!canEdit}
  >
    {text || 'Refresh'}
  </Button>
)

export default Reconnect

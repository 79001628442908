/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { Modal, Link } from '@bufferapp/ui'

import { Header, Body } from '.'

import { ModalFooterContent } from './styles'

const ModalWrapper = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'closeModalCallback' implicitly ha... Remove this comment to see the full error message
  closeModalCallback,
  // @ts-expect-error TS(7031) FIXME: Binding element 'confirmChangePlan' implicitly has... Remove this comment to see the full error message
  confirmChangePlan,
  // @ts-expect-error TS(7031) FIXME: Binding element 'creditCard' implicitly has an 'an... Remove this comment to see the full error message
  creditCard,
  // @ts-expect-error TS(7031) FIXME: Binding element 'currentView' implicitly has an 'a... Remove this comment to see the full error message
  currentView,
  // @ts-expect-error TS(7031) FIXME: Binding element 'cycle' implicitly has an 'any' ty... Remove this comment to see the full error message
  cycle,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasCard' implicitly has an 'any' ... Remove this comment to see the full error message
  hasCard,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isChangingPlan' implicitly has an... Remove this comment to see the full error message
  isChangingPlan,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isSubmittingCreditCard' implicitl... Remove this comment to see the full error message
  isSubmittingCreditCard,
  // @ts-expect-error TS(7031) FIXME: Binding element 'planForReview' implicitly has an ... Remove this comment to see the full error message
  planForReview,
  // @ts-expect-error TS(7031) FIXME: Binding element 'planName' implicitly has an 'any'... Remove this comment to see the full error message
  planName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'planOptions' implicitly has an 'a... Remove this comment to see the full error message
  planOptions,
  // @ts-expect-error TS(7031) FIXME: Binding element 'product' implicitly has an 'any' ... Remove this comment to see the full error message
  product,
  // @ts-expect-error TS(7031) FIXME: Binding element 'saveCardDetails' implicitly has a... Remove this comment to see the full error message
  saveCardDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectPlanForReview' implicitly h... Remove this comment to see the full error message
  selectPlanForReview,
  // @ts-expect-error TS(7031) FIXME: Binding element 'showPlanOptionsCallback' implicit... Remove this comment to see the full error message
  showPlanOptionsCallback,
  // @ts-expect-error TS(7031) FIXME: Binding element 'stripeCustomerId' implicitly has ... Remove this comment to see the full error message
  stripeCustomerId,
  // @ts-expect-error TS(7031) FIXME: Binding element 'toggleCycleCallback' implicitly h... Remove this comment to see the full error message
  toggleCycleCallback,
  // @ts-expect-error TS(7031) FIXME: Binding element 'updateChangePlanModalView' implic... Remove this comment to see the full error message
  updateChangePlanModalView,
  // @ts-expect-error TS(7031) FIXME: Binding element 'currentPlan' implicitly has an 'a... Remove this comment to see the full error message
  currentPlan,
}) => {
  const viewingPlans = currentView === 'plans'
  const viewingCreditCard = currentView === 'creditCard'
  const viewingReviewChanges = currentView === 'review'

  let primaryActionLabel: string

  if (isChangingPlan) {
    primaryActionLabel = 'One Moment...'
  } else if (!hasCard) {
    primaryActionLabel = 'Add Credit Card'
  } else if (viewingPlans) {
    primaryActionLabel = 'Review Changes'
  } else {
    primaryActionLabel = 'Confirm Plan'
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'actionType' implicitly has an 'any' typ... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const renderActionOptions = (actionType) => {
    if (viewingCreditCard) {
      return null
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const renderPrimaryCallback = () => {
      if (viewingPlans) {
        return hasCard
          ? updateChangePlanModalView('review')
          : updateChangePlanModalView('creditCard')
      } else if (viewingReviewChanges) {
        return confirmChangePlan({
          // eslint-disable-next-line react/prop-types
          plan: planForReview.stripePlanName,
          cycle,
          product,
        })
      }
    }

    if (actionType === 'primary') {
      return {
        label: primaryActionLabel,
        disabled: !planForReview || isChangingPlan,
        callback: renderPrimaryCallback,
      }
    } else if (actionType === 'secondary') {
      return {
        label: viewingPlans ? 'Cancel' : `Change ${planName} Plan`,
        callback: viewingPlans ? closeModalCallback : showPlanOptionsCallback,
      }
    }
  }

  return (
    // @ts-expect-error TS(2322)
    <Modal
      width="wide"
      dismissible={false}
      action={renderActionOptions('primary')}
      secondaryAction={renderActionOptions('secondary')}
      footer={
        viewingPlans && (
          <ModalFooterContent>
            {/* eslint-disable-next-line react/prop-types */}
            <Link href={planOptions[product].moreInfo} newTab>
              Learn more about {planName} plans
            </Link>
          </ModalFooterContent>
        )
      }
      closeButton={{ callback: closeModalCallback }}
    >
      <Header
        currentView={currentView}
        cycle={cycle}
        planName={planName}
        toggleCycleCallback={toggleCycleCallback}
      />
      <Body
        currentPlan={currentPlan}
        creditCard={creditCard}
        currentView={currentView}
        cycle={cycle}
        // @ts-expect-error TS(2322) FIXME: Type '{ currentPlan: any; creditCard: any; current... Remove this comment to see the full error message
        hasCard={hasCard}
        isSubmittingCreditCard={isSubmittingCreditCard}
        planForReview={planForReview}
        product={product}
        saveCardDetails={saveCardDetails}
        selectPlanForReview={selectPlanForReview}
        stripeCustomerId={stripeCustomerId}
        updateChangePlanModalView={updateChangePlanModalView}
      />
    </Modal>
  )
}

export default ModalWrapper

import React from 'react'
import {
  Facebook,
  Instagram,
  LinkedIn,
  Pinterest,
  Tiktok,
  Gbp,
  StartPage,
  Mastodon,
  Youtube,
  Threads,
  X,
  Bluesky,
} from '@bufferapp/ui/Icon'
import {
  blue,
  facebook,
  instagram,
  linkedin,
  pinterest,
  twitter,
  tiktok,
  googlebusiness,
  mastodon,
  youtube,
  threads,
} from '@bufferapp/ui/style/colors'
import { LogoWrapper, LogoWrapperMedium } from './style'

export const InstagramLogo = (): JSX.Element => (
  <LogoWrapper className="logo-wrapper" color={instagram}>
    <Instagram size={'large'} />
  </LogoWrapper>
)

export const FacebookLogo = (): JSX.Element => (
  <LogoWrapper className="logo-wrapper" resizeSvg color={'white'}>
    <Facebook size={'extraLarge'} color={facebook} />
  </LogoWrapper>
)

export const TwitterLogo = (): JSX.Element => (
  <LogoWrapper className="logo-wrapper" color={twitter}>
    <X size={'large'} />
  </LogoWrapper>
)

export const LinkedInLogo = (): JSX.Element => (
  <LogoWrapper className="logo-wrapper" color={linkedin}>
    <LinkedIn size={'large'} />
  </LogoWrapper>
)

export const PinterestLogo = (): JSX.Element => (
  <LogoWrapper className="logo-wrapper" resizeSvg color={'white'}>
    <Pinterest size={'large'} color={pinterest} />
  </LogoWrapper>
)

export const TiktokLogo = (): JSX.Element => (
  <LogoWrapper className="logo-wrapper" color={tiktok}>
    <Tiktok size={'large'} />
  </LogoWrapper>
)

export const GoogleBusinessLogo = (): JSX.Element => (
  <LogoWrapper className="logo-wrapper" color={googlebusiness}>
    <Gbp size={'large'} />
  </LogoWrapper>
)

export const StartPageLogo = (): JSX.Element => (
  <LogoWrapper className="logo-wrapper" color={blue}>
    <StartPage size={'large'} />
  </LogoWrapper>
)

export const MastodonLogo = (): JSX.Element => (
  <LogoWrapper className="logo-wrapper" color={mastodon}>
    <Mastodon size={'large'} />
  </LogoWrapper>
)

export const BlueskyLogo = (): JSX.Element => (
  <LogoWrapper className="logo-wrapper" color={mastodon}>
    <Bluesky size={'large'} />
  </LogoWrapper>
)

export const YoutubeLogo = (): JSX.Element => (
  <LogoWrapper className="logo-wrapper" color={youtube}>
    <Youtube size={'large'} />
  </LogoWrapper>
)

export const ThreadsLogo = (): JSX.Element => (
  <LogoWrapper className="logo-wrapper" color={threads}>
    <Threads size={'large'} />
  </LogoWrapper>
)

export const InstagramLogoMedium = (): JSX.Element => (
  <LogoWrapperMedium className="logo-wrapper-medium" color={instagram}>
    <Instagram size={'medium'} />
  </LogoWrapperMedium>
)

export const FacebookLogoMedium = (): JSX.Element => (
  <LogoWrapperMedium className="logo-wrapper-medium" resizeSvg color={'white'}>
    <Facebook size={'large'} color={facebook} />
  </LogoWrapperMedium>
)

export const TwitterLogoMedium = (): JSX.Element => (
  <LogoWrapperMedium className="logo-wrapper-medium" color={twitter}>
    <X size={'medium'} />
  </LogoWrapperMedium>
)

export const LinkedInLogoMedium = (): JSX.Element => (
  <LogoWrapperMedium className="logo-wrapper-medium" color={linkedin}>
    <LinkedIn size={'medium'} />
  </LogoWrapperMedium>
)

export const PinterestLogoMedium = (): JSX.Element => (
  <LogoWrapperMedium className="logo-wrapper-medium" resizeSvg color={'white'}>
    <Pinterest size={'medium'} color={pinterest} />
  </LogoWrapperMedium>
)

export const TiktokLogoMedium = (): JSX.Element => (
  <LogoWrapperMedium className="logo-wrapper-medium" color={tiktok}>
    <Tiktok size={'medium'} />
  </LogoWrapperMedium>
)

export const GoogleBusinessLogoMedium = (): JSX.Element => (
  <LogoWrapperMedium className="logo-wrapper-medium" color={googlebusiness}>
    <Gbp size={'medium'} />
  </LogoWrapperMedium>
)

export const StartPageLogoMedium = (): JSX.Element => (
  <LogoWrapperMedium className="logo-wrapper-medium" color={blue}>
    <StartPage size={'medium'} />
  </LogoWrapperMedium>
)

export const MastodonLogoMedium = (): JSX.Element => (
  <LogoWrapperMedium className="logo-wrapper-medium" color={blue}>
    <Mastodon size={'medium'} />
  </LogoWrapperMedium>
)

export const YoutubeLogoMedium = (): JSX.Element => (
  <LogoWrapperMedium className="logo-wrapper-medium" color={youtube}>
    <Youtube size={'medium'} />
  </LogoWrapperMedium>
)

export default {
  instagram: InstagramLogo,
  facebook: FacebookLogo,
  twitter: TwitterLogo,
  linkedin: LinkedInLogo,
  pinterest: PinterestLogo,
  tiktok: TiktokLogo,
  googlebusinessprofile: GoogleBusinessLogo,
  googlebusiness: GoogleBusinessLogo,
  startpage: StartPageLogo,
  mastodon: MastodonLogo,
  youtube: YoutubeLogo,
  threads: ThreadsLogo,
  bluesky: BlueskyLogo,
  instagram_medium: InstagramLogoMedium,
  facebook_medium: FacebookLogoMedium,
  twitter_medium: TwitterLogoMedium,
  linkedin_medium: LinkedInLogoMedium,
  pinterest_medium: PinterestLogoMedium,
  tiktok_medium: TiktokLogoMedium,
  googlebusinessprofile_medium: GoogleBusinessLogoMedium,
  startpage_medium: StartPageLogoMedium,
  mastodon_medium: MastodonLogoMedium,
  youtube_medium: YoutubeLogoMedium,
}

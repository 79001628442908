import { asyncDataFetchActionTypes } from '@buffer-mono/async-data-fetch'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import keyWrapper from '@bufferapp/keywrapper'

export const actionTypes = keyWrapper('CHANNEL_LIST', {
  GET_CHANNEL_LIST: 'GET_CHANNEL_LIST',
  CLOSE: 'CLOSE',
  REMOVE_MESSAGE: 'REMOVE_MESSAGE',
  REMOVE_CHANNEL: 'REMOVE_CHANNEL',
  CLOSE_CHANNEL_LIST: 'CLOSE_CHANNEL_LIST',
  RESET_CONNECTED_PAGES_STATE: 'RESET_CONNECTED_PAGES_STATE',
  SHOW_CHANNEL_CONNECT_SUCCESS_NOTIFICATION:
    'SHOW_CHANNEL_CONNECT_SUCCESS_NOTIFICATION',
  HIDE_CHANNEL_CONNECT_SUCCESS_NOTIFICATION:
    'HIDE_CHANNEL_CONNECT_SUCCESS_NOTIFICATION',
  SHOW_CHANNEL_CONNECT_ERROR_NOTIFICATION:
    'SHOW_CHANNEL_CONNECT_ERROR_NOTIFICATION',
  HIDE_CHANNEL_CONNECT_ERROR_NOTIFICATION:
    'HIDE_CHANNEL_CONNECT_ERROR_NOTIFICATION',
})

export const errorTypes = keyWrapper('CHANNEL_LIST', {
  CHANNEL_STEALING: 'CHANNEL_STEALING',
})

const initialState = {
  channels: [],
  channelsToRemove: [],
  open: false,
  service: null,
  showChannelConnectSuccessNotification: false,
  isReconnecting: false,
  showChannelConnectErrorNotification: false,
  errorType: null,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'service' implicitly has an 'any' type.
function capitalizeServiceName(service) {
  return `${service.charAt(0).toUpperCase()}${service.slice(1)}`
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `getFacebookPages_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        open: true,
        service: 'facebook',
      }
    case `getFacebookGroups_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        open: true,
        service: 'facebook',
      }
    case `getInstagramBusinessPages_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        open: true,
        service: 'instagram',
      }
    case `getTwitterUserCredentials_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        open: true,
        service: 'twitter',
      }
    case `getChannelList_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        channels: action.result,
      }
    case `reconnectTwitterChannel_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        channels: state.channels.map((channel) => {
          // @ts-expect-error TS(2339) FIXME: Property '_id' does not exist on type 'never'.
          if (channel._id === action.result._id) {
            return action.result
          }

          return channel
        }),
        message: `Yay! Your account ${action.result.name} has been reconnected successfully!`,
      }
    case `reconnectChannel_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        channels: state.channels.map((channel) => {
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
          if (channel.id === action.result.id) {
            return action.result
          }

          return channel
        }),
        message: `Yay! Your account ${action.result.name} has been reconnected successfully!`,
      }
    case `reconnectChannel_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      return {
        ...state,
        message: `There was an issue refreshing your account on ${capitalizeServiceName(
          action.args.service,
        )}!`,
      }
    case actionTypes.REMOVE_MESSAGE:
      return {
        ...state,
        message: undefined,
      }
    case `createChannels_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        channels: state.channels.concat(action.result),
      }
    case actionTypes.SHOW_CHANNEL_CONNECT_SUCCESS_NOTIFICATION:
      return {
        ...state,
        showChannelConnectSuccessNotification: true,
        isReconnecting: action.isReconnecting,
      }
    case actionTypes.HIDE_CHANNEL_CONNECT_SUCCESS_NOTIFICATION:
      return {
        ...state,
        showChannelConnectSuccessNotification: false,
        isReconnecting: false,
      }
    case actionTypes.SHOW_CHANNEL_CONNECT_ERROR_NOTIFICATION:
      return {
        ...state,
        showChannelConnectErrorNotification: true,
        errorType: action.errorType,
      }
    case actionTypes.HIDE_CHANNEL_CONNECT_ERROR_NOTIFICATION:
      return {
        ...state,
        showChannelConnectErrorNotification: false,
        errorType: null,
      }
    case actionTypes.REMOVE_CHANNEL:
      state.channelsToRemove.push(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        state.channels.find((channel) => channel._id === action.channelId),
      )
      return {
        ...state,
        channels: state.channels.filter(
          // @ts-expect-error TS(2339) FIXME: Property '_id' does not exist on type 'never'.
          (channel) => channel._id !== action.channelId,
        ),
      }
    case `removeChannel_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      return {
        ...state,
        channels: action.result,
        channelsToRemove: state.channelsToRemove.filter(
          // @ts-expect-error TS(2339) FIXME: Property '_id' does not exist on type 'never'.
          (channel) => channel._id !== action.args.channelId,
        ),
      }
    case `removeChannel_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      state.channels.push(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
        state.channelsToRemove.find(
          // @ts-expect-error TS(2339) FIXME: Property '_id' does not exist on type 'never'.
          (channel) => channel._id === action.args.channelId,
        ),
      )
      return {
        ...state,
        channelsToRemove: state.channelsToRemove.filter(
          // @ts-expect-error TS(2339) FIXME: Property '_id' does not exist on type 'never'.
          (channel) => channel._id !== action.args.channelId,
        ),
      }
    case actionTypes.CLOSE_CHANNEL_LIST:
      return {
        ...state,
        open: false,
      }
    default:
      return state
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectChannelListLoggedIn = (state) => state.channelList.open
// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectChannelListService = (state) => state.channelList.service

export const actions = {
  getChannelList: () => ({
    type: actionTypes.GET_CHANNEL_LIST,
  }),
  close: () => ({
    type: actionTypes.CLOSE_CHANNEL_LIST,
  }),
  removeMessage: () => ({
    type: actionTypes.REMOVE_MESSAGE,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'channelId' implicitly has an 'any' type... Remove this comment to see the full error message
  removeChannel: (channelId) => ({
    type: actionTypes.REMOVE_CHANNEL,
    channelId,
  }),
  hideChannelConnectSuccessNotification: () => ({
    type: actionTypes.HIDE_CHANNEL_CONNECT_SUCCESS_NOTIFICATION,
  }),
  showChannelConnectSuccessNotification: (isReconnecting = false) => ({
    type: actionTypes.SHOW_CHANNEL_CONNECT_SUCCESS_NOTIFICATION,
    isReconnecting,
  }),
  showChannelConnectErrorNotification: (errorType = null) => ({
    type: actionTypes.SHOW_CHANNEL_CONNECT_ERROR_NOTIFICATION,
    errorType,
  }),
  hideChannelConnectErrorNotification: () => ({
    type: actionTypes.HIDE_CHANNEL_CONNECT_ERROR_NOTIFICATION,
  }),
  // Clears pages state for each service to allow a user to
  // go through auth flows multiple times.
  resetConnectedPagesState: () => ({
    type: actionTypes.RESET_CONNECTED_PAGES_STATE,
  }),
}

import {
  ChannelThirdPartyService,
  ChannelType,
} from '../../../../../channel-connections/interfaces'
import { capitalizeFirstLetter } from '../../../../../shared-utils'
import { HC_UTM_PARAMS } from '../../../../../shared-utils/constants'

export const TRIAL_CHANNELS_LIMIT = 15
export const FREE_CHANNELS_LIMIT = 8

export const getServiceNameForBody = (
  service: ChannelThirdPartyService,
): string => {
  if (service === ChannelThirdPartyService.instagram) {
    return capitalizeFirstLetter(ChannelThirdPartyService.facebook)
  }
  return capitalizeFirstLetter(service)
}

export const isPinterestOrTwitter = (
  service: ChannelThirdPartyService,
): boolean => {
  return (
    service === ChannelThirdPartyService.pinterest ||
    service === ChannelThirdPartyService.twitter
  )
}

export const isInstagramOrFacebook = (
  service: ChannelThirdPartyService,
): boolean => {
  return (
    service === ChannelThirdPartyService.instagram ||
    service === ChannelThirdPartyService.facebook
  )
}

export const isSingleFlowType = (
  service: ChannelThirdPartyService,
): boolean => {
  const singleFlowTypes = [
    ChannelThirdPartyService.mastodon,
    ChannelThirdPartyService.pinterest,
    ChannelThirdPartyService.twitter,
    ChannelThirdPartyService.tiktok,
    ChannelThirdPartyService.threads,
    ChannelThirdPartyService.instagram_login_api,
  ]
  return singleFlowTypes.includes(service)
}

export const getGuideUrl = (
  service: ChannelThirdPartyService,
  type?: ChannelType,
): string => {
  switch (service) {
    case ChannelThirdPartyService.facebook:
      return type === ChannelType.group
        ? `https://support.buffer.com/article/570-connecting-facebook-groups-and-troubleshooting-connections?${HC_UTM_PARAMS}`
        : `https://support.buffer.com/article/569-connecting-your-facebook-page-to-buffer?${HC_UTM_PARAMS}`
    case ChannelThirdPartyService.linkedin:
      return `https://support.buffer.com/article/560-using-linkedin-with-buffer?${HC_UTM_PARAMS}`
    default:
      return `https://support.buffer.com/article/564-connecting-your-channels-to-buffer?${HC_UTM_PARAMS}`
  }
}

export const getServiceName = (service: ChannelThirdPartyService): string => {
  if (service === ChannelThirdPartyService.googlebusiness) return 'Google'
  if (service === ChannelThirdPartyService.youtube) return 'YouTube'
  if (service === ChannelThirdPartyService.tiktok) return 'TikTok'
  if (service === ChannelThirdPartyService.linkedin) return 'LinkedIn'
  if (service === ChannelThirdPartyService.twitter) return 'Twitter / X'
  if (service === ChannelThirdPartyService.instagram_login_api)
    return 'Instagram'
  return capitalizeFirstLetter(service)
}

export const getChannelTypeByService = (
  service: ChannelThirdPartyService,
): ChannelType => {
  const channelTypes: { [key: string]: ChannelType } = {
    facebook: ChannelType.page,
    instagram: ChannelType.page,
    linkedin: ChannelType.page,
    twitter: ChannelType.account,
    pinterest: ChannelType.profile,
    tiktok: ChannelType.account,
    youtube: ChannelType.channel,
    googlebusiness: ChannelType.business,
    mastodon: ChannelType.profile,
    threads: ChannelType.profile,
    bluesky: ChannelType.profile,
  }
  return channelTypes[service] || ChannelType.account
}

export enum OAuthFlowErrorType {
  lifetimeChannelConnectionError = 'lifetimeChannelConnectionError',
  noChannelsError = 'noChannelsError',
  stealingNotAllowed = 'stealingNotAllowed',
  unexpectedError = 'unexpectedError',
  notAllowedService = 'notAllowedService',
  noCodeFoundInCallbackUrl = 'noCodeFoundInCallbackUrl',
  notEnoughPermissions = 'notEnoughPermissions',
  failedToGetCredentials = 'failedToGetCredentials',
}

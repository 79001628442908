import React, { type ReactNode } from 'react'
import styled from 'styled-components'

import { blue } from '@bufferapp/ui/style/colors'
import { TitleContainer } from '../../account-components'
import { useAccount } from '../../web/src/context/Account'
import { Flex, Heading, Text } from '@buffer-mono/popcorn'
import { Text as OldText } from '@bufferapp/ui'

const OnboardingSection = styled.section`
  display: flex;
  min-height: 100%;
  background: url('https://buffer-ui.s3.amazonaws.com/Swirl+Background+Connection.png');
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: 25%;
`

const OnboardingTitle = styled(TitleContainer)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  text-align: center;
`

const Title = styled(OldText)`
  margin-bottom: 1rem;
`

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-content: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  width: 100%;

  .skipButton {
    color: ${blue};
    font-weight: bold;
  }
`

export const OnboardingPage = (args: {
  title: ReactNode
  subtitle: ReactNode
  children: ReactNode
  usePopcorn?: boolean
}): JSX.Element => {
  const { title, subtitle, children, usePopcorn = false } = args
  const account = useAccount()
  return (
    <OnboardingSection>
      <Main>
        {usePopcorn && (
          <OnboardingTitle>
            <Flex gap="2xs" direction="column" align="center">
              <Heading size="xlarge">{title}</Heading>
              <Text color="subtle">{subtitle}</Text>
            </Flex>
          </OnboardingTitle>
        )}
        {!usePopcorn && (
          <OnboardingTitle>
            <Title type="h1">{title}</Title>
            <OldText type="p" color="grayDark">
              {subtitle}
            </OldText>
          </OnboardingTitle>
        )}
        {/* @ts-expect-error TS(2339) FIXME: Property 'isLoading' does not exist on type 'Accou... Remove this comment to see the full error message */}
        <Content>{account && !account.isLoading && children}</Content>
      </Main>
    </OnboardingSection>
  )
}

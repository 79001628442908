import React, { useState } from 'react'
import { Avatar, DropdownMenu, Text } from '@bufferapp/ui'
import { grayDark } from '@bufferapp/ui/style/colors'
import MoreIcon from '@bufferapp/ui/Icon/Icons/More'
import { useSplitEnabled } from '@buffer-mono/features'

import StatusSection from '../StatusSection'

import { AvatarWrapper, ChannelRow, TextStyled } from './styles'

import { fallbackAvatarUrl } from '../../ChannelsPage/ChannelsPage'

import type { CardRowProps } from './types'
import { useGetMenuItems } from './hooks/useGetMenuItems'

export const CardRow = ({
  avatar,
  apiVersion,
  canEdit,
  descriptor,
  id,
  isDisconnected,
  isLocked,
  name,
  displayName,
  selectChannelToRemove,
  service,
  setShowChannelUnlockingModal,
  type,
  location,
  serverUrl,
  needsRefreshing,
}: CardRowProps): JSX.Element => {
  const { isEnabled: isHideTwitterConnectionEnabled } = useSplitEnabled(
    'hide-twitter-connection-option',
  )

  const shouldDisableRefreshButton =
    service.toLowerCase() === 'twitter' && isHideTwitterConnectionEnabled

  const onRefreshConnectionClick = (cta: string): void => {
    if (window.appshell?.actions) {
      const { actions } = window.appshell || {}

      actions.connectChannel({
        selectedService: service.toLowerCase(),
        isRefreshingConnection: true,
        cta,
        name,
        selectedRefreshChannelId: id,
        authMethod: apiVersion,
      })
    }
  }

  const menuItems = useGetMenuItems({
    apiVersion,
    isLocked,
    canEdit,
    channelAvatar: avatar,
    channelUrl: serverUrl,
    service,
    type,
    channelId: id,
    shouldDisableRefresh: shouldDisableRefreshButton,
    channelName: name,
    onUnlockClick: () => {
      setShowChannelUnlockingModal(true)
    },
    selectChannelToRemove: () =>
      selectChannelToRemove({ id, name, avatar, service, type, descriptor }),
  })

  return (
    <ChannelRow data-testid={`cardrow-${id}`} isLocked={isLocked}>
      <AvatarWrapper>
        <Avatar
          src={avatar}
          alt={displayName || name}
          network={service}
          fallbackUrl={fallbackAvatarUrl}
        />
      </AvatarWrapper>
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <TextStyled type="p" title={name}>
          {name || displayName}
        </TextStyled>
        {location && <Text type="p">{location}</Text>}
        {serverUrl && <Text type="p">{serverUrl}</Text>}
        <Text type="p">{descriptor}</Text>
      </div>
      <StatusSection
        canEdit={canEdit}
        isDisconnected={isDisconnected}
        isLocked={isLocked}
        onRefreshConnectionClick={(): void =>
          onRefreshConnectionClick(
            `account-channels-channelRefreshButton-${service.toLowerCase()}-1`,
          )
        }
        needsRefreshing={needsRefreshing}
      />
      <div>
        <DropdownMenu
          // @ts-expect-error TS(2322) FIXME: Type '{ ariaLabel: string; ariaLabelPopup: string;... Remove this comment to see the full error message
          ariaLabel="Channel Menu"
          ariaLabelPopup="Channel Menu"
          menubarItem={<MoreIcon color={grayDark} />}
          items={menuItems}
          xPosition="right"
        />
      </div>
    </ChannelRow>
  )
}

import { useMutation } from '@apollo/client'
import { Modal, Text } from '@bufferapp/ui'
import { Warning } from '@bufferapp/ui/Icon'
import { redDark } from '@bufferapp/ui/style/colors'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import ChannelSummary from '../../../components/ChannelSummary'

import { CHANNELS_LOCK } from '../../../../../apollo/mutations/channels'
import { GET_CHANNELS_FOR_ORGANIZATION } from '../../../../../apollo/queries/channels'

import { handleChannelUpdates } from './utils/unlocked-channels'

import { useAccount } from '../../../../../context/Account'
import type { Channel } from '../../../auth-flows/shared/SelectChannelList/utils'
import {
  Body,
  ChannelListHeader,
  ChannelListWrapper,
  CheckboxWrapper,
  ErrorWrapper,
  Header,
  LabelRow,
} from './styles'
import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'

const ChannelUnlockingModal = (args: {
  channels: Channel[]
  setShowChannelUnlockingModal: (arg: boolean) => void
  setShowChannelsUpdatedNotification: (arg: boolean) => void
}): JSX.Element => {
  const {
    channels,
    setShowChannelUnlockingModal,
    setShowChannelsUpdatedNotification,
  } = args
  const [updatedChannels, setUpdatedChannels] = useState(channels)
  const numberOfUnlockedChannels = updatedChannels.filter(
    (channel) => !channel.isLocked,
  ).length

  const account = useAccount()
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties
  const organizationId = account?.currentOrganization?.id

  const [channelsLock] = useMutation(CHANNELS_LOCK, {
    onCompleted() {
      setShowChannelUnlockingModal(false)
      setShowChannelsUpdatedNotification(true)
      const timeToHideNotification = 6000
      setTimeout(() => {
        setShowChannelsUpdatedNotification(false)
      }, timeToHideNotification)
    },
    onError() {},
    refetchQueries: [{ query: GET_CHANNELS_FOR_ORGANIZATION }],
  })

  const currentOrganization = useSelector(
    (state) => state.account.currentOrganization,
  )

  const channelLimit =
    currentOrganization && currentOrganization.channelLimit
      ? currentOrganization.channelLimit
      : null

  const isOverChannelLimit = numberOfUnlockedChannels > channelLimit
  const noChannelsHaveBeenUpdated =
    JSON.stringify(updatedChannels) === JSON.stringify(channels)

  useEffect(() => {
    if (organizationId) {
      BufferTracker.channelUnlockModalViewed({
        organizationId,
        ...commonTrackingProperties,
        clientName: Client.PublishWeb,
      })
    }
  }, [organizationId, commonTrackingProperties])

  return (
    // @ts-expect-error TS(2322)
    <Modal
      action={{
        label: 'Save',
        callback: (): void => {
          channelsLock({
            variables: {
              organizationId: currentOrganization.id,
              channelIdsToLock: updatedChannels
                .filter((c: Channel) => c.isLocked)
                .map((c: Channel) => c.id),
              channelIdsToUnlock: updatedChannels
                .filter((c: Channel) => !c.isLocked)
                .map((c: Channel) => c.id),
            },
          })
        },
        disabled: noChannelsHaveBeenUpdated || isOverChannelLimit,
      }}
      secondaryAction={{
        label: 'Cancel',
        callback: (): void => {
          setShowChannelUnlockingModal(false)
        },
      }}
      dismissible={false}
      width="wide"
    >
      <Header>
        <Text type="h2">Select channels to keep</Text>
      </Header>
      <Body>
        <Text type="p">
          Your current plan supports up to {channelLimit} channels. Please
          select which {channelLimit} channels you’d like to keep connected to
          Buffer. All others will be saved but locked. Check out our channel
          limits guide for more details.
        </Text>
        <ChannelListHeader>
          <Text type="p">{channels.length} Channels</Text>
          <Text type="p">
            <i>{numberOfUnlockedChannels} Selected</i>
          </Text>
        </ChannelListHeader>
        <ChannelListWrapper>
          {channels.map((channel) => {
            return (
              <LabelRow
                key={channel.id}
                data-testid={`label-row-${channel.id}`}
              >
                <CheckboxWrapper>
                  <input
                    type="checkbox"
                    id={channel.id}
                    name={channel.id}
                    defaultChecked={!channel.isLocked}
                    onChange={(): void =>
                      handleChannelUpdates({
                        updatedChannels,
                        currentChannel: channel,
                        updateChannelsCallback: setUpdatedChannels,
                      })
                    }
                  />
                </CheckboxWrapper>
                <ChannelSummary
                  avatar={channel.avatar}
                  descriptor={channel.descriptor || channel.type}
                  id={channel.id}
                  name={channel.name}
                  service={channel.type}
                />
              </LabelRow>
            )
          })}
        </ChannelListWrapper>
        {isOverChannelLimit ? (
          <ErrorWrapper>
            <Warning color={redDark} />{' '}
            <Text type="span">
              A maximum number of {channelLimit} channels may be selected.
            </Text>
          </ErrorWrapper>
        ) : null}
      </Body>
    </Modal>
  )
}

ChannelUnlockingModal.propTypes = {
  channels: PropTypes.array,
  setShowChannelsUpdatedNotification: PropTypes.func,
  setShowChannelUnlockingModal: PropTypes.func,
}

export default ChannelUnlockingModal

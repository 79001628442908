import {
  asyncDataFetchActions,
  asyncDataFetchActionTypes,
} from '@buffer-mono/async-data-fetch'
import { actions as channelListActions } from '../channel-list'
import { actionTypes as creditCardActionTypes } from '../credit-card'
import { actionTypes, actions } from './reducer'
import { actions as analyticsActions } from '@bufferapp/analytics-middleware'

export const ERROR_CODES = {
  parameter_missing:
    'Yikes! There seems to be some card information missing or invalid. Up for checking the form and submitting it again?',
}

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  switch (action.type) {
    case creditCardActionTypes.CC_CARD_SAVE_SUCCESS:
    case actionTypes.GET_PLAN_INFORMATION:
      store.dispatch(
        asyncDataFetchActions.fetch({
          name: 'getBillingInformation',
          args: {},
        }),
      )
      break
    case actionTypes.CANCEL_SUBSCRIPTION:
      store.dispatch(
        asyncDataFetchActions.fetch({
          name: 'cancelSubscription',
          args: {},
        }),
      )
      break
    case `cancelSubscription_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      store.dispatch(actions.closeCancelSubscriptionModal())
      store.dispatch(actions.getBillingInformation())
      store.dispatch(channelListActions.getChannelList())
      break
    case actionTypes.REENABLE_SUBSCRIPTION:
      store.dispatch(
        asyncDataFetchActions.fetch({
          name: 'reenableSubscription',
          args: {
            cycle: store.getState().billing.plan.interval,
          },
        }),
      )
      break
    case `reenableSubscription_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      store.dispatch(actions.getBillingInformation())
      store.dispatch(channelListActions.getChannelList())
      break
    case actionTypes.SUBMIT_CHURN_SURVEY:
      store.dispatch(
        analyticsActions.trackEvent('churnSurveyCompleted', {
          product: 'analyze',
          reason: action.data.reason,
          details: action.data.details,
          organizationId: store.getState().currentOrganizationId,
        }),
      )

      break
    case `changePlan_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      store.dispatch(channelListActions.getChannelList())
      store.dispatch(actions.getBillingInformation())
      break
    case `changeBillingCycle_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      store.dispatch(actions.getBillingInformation())
      break
    case actionTypes.CHANGE_BILLING_CYCLE:
      store.dispatch(
        asyncDataFetchActions.fetch({
          name: 'changeBillingCycle',
          args: {
            cycle: action.cycle,
            planId: store.getState().billing.plan.id,
          },
        }),
      )
      break
    case actionTypes.CHANGE_PLAN:
      store.dispatch(
        asyncDataFetchActions.fetch({
          name: 'changePlan',
          args: {
            trialing: !!store.getState().billing.trial,
            plan: action.plan,
            cycle: action.cycle,
          },
        }),
      )
      break
    case 'APP_INIT':
      if (
        store
          .getState()
          .router.location.search.includes('upgradeToPremium=true')
      ) {
        store.dispatch(actions.openChangePlanModal())
      }
      break
    default:
      break
  }
  return next(action)
}

import {
  asyncDataFetchActions,
  asyncDataFetchActionTypes,
} from '@buffer-mono/async-data-fetch'
import { actions, actionTypes } from './reducer'
// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  switch (action.type) {
    case actionTypes.GET_CHANNEL_LIST:
      store.dispatch(
        asyncDataFetchActions.fetch({
          name: 'getChannelList',
          args: {},
        }),
      )
      break
    case actionTypes.REMOVE_CHANNEL:
      store.dispatch(
        asyncDataFetchActions.fetch({
          name: 'removeChannel',
          args: {
            channelId: action.channelId,
          },
        }),
      )
      break
    case `createChannels_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      store.dispatch(actions.close())
      break
    default:
      break
  }
  return next(action)
}

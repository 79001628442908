import { connect } from 'react-redux'
import ChannelList from './components/ChannelList'
import { actions } from './reducer'
import { getChannelLimitsByPlanName } from '../shared-utils'

// @ts-expect-error TS(7006) FIXME: Parameter 'channels' implicitly has an 'any' type.
const filterPages = (channels, pages) =>
  pages.filter(
    // @ts-expect-error TS(7006) FIXME: Parameter 'page' implicitly has an 'any' type.
    (page) => !channels.some((channel) => channel.serviceId === page.id),
  )

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'channelList' does not exist on type 'Def... Remove this comment to see the full error message
    ...state.channelList,
    // @ts-expect-error TS(2339) FIXME: Property 'channelList' does not exist on type 'Def... Remove this comment to see the full error message
    channels: state.channelList.channels.filter(
      // @ts-expect-error TS(7031) FIXME: Binding element 'serviceType' implicitly has an 'a... Remove this comment to see the full error message
      ({ serviceType }) => serviceType !== 'startpage',
    ),
    limit:
      state.billing.plan && state.billing.plan.id
        ? getChannelLimitsByPlanName(state.billing.plan.id)
        : null,
    facebookPages: filterPages(
      // @ts-expect-error TS(2339) FIXME: Property 'channelList' does not exist on type 'Def... Remove this comment to see the full error message
      state.channelList.channels,
      // @ts-expect-error TS(2339) FIXME: Property 'oauthFacebook' does not exist on type 'D... Remove this comment to see the full error message
      state.oauthFacebook.pages,
    ),
    instagramPages: filterPages(
      // @ts-expect-error TS(2339) FIXME: Property 'channelList' does not exist on type 'Def... Remove this comment to see the full error message
      state.channelList.channels,
      // @ts-expect-error TS(2339) FIXME: Property 'oauthInstagram' does not exist on type '... Remove this comment to see the full error message
      state.oauthInstagram.pages,
    ),
    twitterAccount: filterPages(
      // @ts-expect-error TS(2339) FIXME: Property 'channelList' does not exist on type 'Def... Remove this comment to see the full error message
      state.channelList.channels,
      // @ts-expect-error TS(2339) FIXME: Property 'oauthTwitter' does not exist on type 'De... Remove this comment to see the full error message
      state.oauthTwitter.pages,
    ),
    linkedinAccount: filterPages(
      // @ts-expect-error TS(2339) FIXME: Property 'channelList' does not exist on type 'Def... Remove this comment to see the full error message
      state.channelList.channels,
      // @ts-expect-error TS(2339) FIXME: Property 'oauthLinkedin' does not exist on type 'D... Remove this comment to see the full error message
      state.oauthLinkedin.pages,
    ),
  }),
  (dispatch) => ({
    getChannelList: () => dispatch(actions.getChannelList()),
    close: () => dispatch(actions.close()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    removeChannel: (id) => dispatch(actions.removeChannel(id)),
    removeMessage: () => dispatch(actions.removeMessage()),
  }),
)(ChannelList)

export * from './reducer'
export { default as middleware } from './middleware'

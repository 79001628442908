import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { useSplitEnabled } from '@buffer-mono/features'

import { getProductUrl } from '~/legacy/shared-utils'
import { useAccount } from '../../context/Account'

import {
  AllChannelsIcon,
  AudienceIcon,
  BellIcon,
  BuildingIcon,
  DollarIcon,
  FlaskConicalIcon,
  GripIcon,
  MessageCircleHeartIcon,
  Sidebar,
  SlidersVerticalIcon,
  Text,
  UserIcon,
} from '@buffer-mono/popcorn'

import style from './NewAppMenu.module.css'

const AppMenu = (): JSX.Element => {
  const account = useAccount()
  const { currentOrganization } = account
  const channelCountBadge = currentOrganization?.channels?.length || 0

  const { isEnabled: channelSettingsEnabled } = useSplitEnabled(
    'channel-settings-refresh',
  )

  return (
    <Sidebar.Provider className={style.sidebarProvider}>
      <Sidebar className={style.sidebarWrapper}>
        <Sidebar.Content className={style.sidebar}>
          {/* Account Section */}
          <Sidebar.Header className={style.sidebarHeader}>
            <Text as="h2" size="lg" weight="bold">
              Settings
            </Text>
          </Sidebar.Header>
          <Sidebar.Group>
            <Sidebar.GroupLabel>Account</Sidebar.GroupLabel>
            <Sidebar.GroupContent>
              <Sidebar.List>
                <Sidebar.ListItem>
                  <Sidebar.Button
                    prefix={<UserIcon />}
                    as={'a'}
                    href={`${getProductUrl('publish')}/settings`}
                  >
                    Profile
                  </Sidebar.Button>
                </Sidebar.ListItem>
                <Sidebar.ListItem>
                  <Sidebar.Button
                    prefix={<SlidersVerticalIcon />}
                    as={'a'}
                    href={`${getProductUrl('publish')}/settings/preferences`}
                  >
                    Preferences
                  </Sidebar.Button>
                </Sidebar.ListItem>
                <Sidebar.ListItem>
                  <Sidebar.Button
                    prefix={<BellIcon />}
                    as={'a'}
                    href={`${getProductUrl('publish')}/settings/notifications`}
                  >
                    Notifications
                  </Sidebar.Button>
                </Sidebar.ListItem>
              </Sidebar.List>
            </Sidebar.GroupContent>
          </Sidebar.Group>

          {/* Organization Section */}
          <Sidebar.Group>
            <Sidebar.GroupLabel>Organization</Sidebar.GroupLabel>
            <Sidebar.GroupContent>
              <Sidebar.List>
                <Sidebar.ListItem>
                  <Sidebar.Button
                    prefix={<BuildingIcon />}
                    as={'a'}
                    href={`${getProductUrl('publish')}/settings/general`}
                  >
                    General
                  </Sidebar.Button>
                </Sidebar.ListItem>
                <Sidebar.ListItem>
                  {channelSettingsEnabled && (
                    <Sidebar.Button
                      prefix={<AllChannelsIcon />}
                      as={'a'}
                      href={`${getProductUrl('publish')}/settings/channels`}
                      suffix={<Text>{channelCountBadge}</Text>}
                    >
                      Channels
                    </Sidebar.Button>
                  )}
                  {!channelSettingsEnabled && (
                    <Sidebar.Button
                      prefix={<AllChannelsIcon />}
                      as={NavLink}
                      to={'/channels'}
                      suffix={<Text>{channelCountBadge}</Text>}
                    >
                      Channels
                    </Sidebar.Button>
                  )}
                </Sidebar.ListItem>
                <Sidebar.ListItem>
                  <Sidebar.Button
                    prefix={<AudienceIcon />}
                    as={'a'}
                    href={`${getProductUrl('publish')}/settings/team`}
                  >
                    Team
                  </Sidebar.Button>
                </Sidebar.ListItem>
                <Sidebar.ListItem>
                  <Sidebar.Button
                    prefix={<DollarIcon />}
                    as={NavLink}
                    to={'/billing'}
                  >
                    Billing
                  </Sidebar.Button>
                </Sidebar.ListItem>
              </Sidebar.List>
            </Sidebar.GroupContent>
          </Sidebar.Group>

          {/* Other Section */}
          <Sidebar.Group>
            <Sidebar.GroupLabel>Other</Sidebar.GroupLabel>
            <Sidebar.GroupContent>
              <Sidebar.List>
                <Sidebar.ListItem>
                  <Sidebar.Button
                    prefix={<GripIcon />}
                    as={'a'}
                    href={`${getProductUrl('publish')}/settings/apps-extras`}
                  >
                    Apps & Extras
                  </Sidebar.Button>
                </Sidebar.ListItem>
                <Sidebar.ListItem>
                  <Sidebar.Button
                    prefix={<FlaskConicalIcon />}
                    as={'a'}
                    href={`${getProductUrl('publish')}/settings/beta`}
                  >
                    Beta Features
                  </Sidebar.Button>
                </Sidebar.ListItem>
                <Sidebar.ListItem>
                  <Sidebar.Button
                    prefix={<MessageCircleHeartIcon />}
                    as={'a'}
                    href={`${getProductUrl('publish')}/settings/refer-a-friend`}
                  >
                    Refer a Friend
                  </Sidebar.Button>
                </Sidebar.ListItem>
              </Sidebar.List>
            </Sidebar.GroupContent>
          </Sidebar.Group>
        </Sidebar.Content>
      </Sidebar>
    </Sidebar.Provider>
  )
}

AppMenu.propTypes = {
  navigate: PropTypes.func,
  pathname: PropTypes.string,
}

export default AppMenu

import React from 'react'
import PropTypes from 'prop-types'
import { Button, Text } from '@bufferapp/ui'

import {
  BlockWrapper,
  Card,
  CardBody,
  CardFooter,
} from '../../../../../../account-components'

import TaxStatusDetails, {
  type TaxIdProps,
} from './components/TaxStatusDetails'
import Address from './components/Address'

import useScrollToHash from '../../../../hooks/useScrollToHash'
import useGetPortalSession from './hooks/useGetPortalSession'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { useAccount } from '../../../../context/Account'
import { BusinessInformationFooter } from './styles'

type AddressProps = {
  line1: string
  line2: string
  city: string
  state: string
  postalCode: string
  country: string
}

export type TaxStatusProps = {
  taxIds: TaxIdProps['taxIds']
  taxExempt: string
  address: AddressProps
}

const BillingInformation = ({
  taxIds,
  taxExempt,
  address,
}: TaxStatusProps): JSX.Element => {
  const account = useAccount()
  const { currentOrganization } = account
  useScrollToHash('#business-information')

  const { openBillingPortal, errorMessage } = useGetPortalSession()

  return (
    <BlockWrapper id="business-information">
      <Card title="Business Information">
        <CardBody>
          <Address address={address} />
          <TaxStatusDetails taxIds={taxIds} taxExempt={taxExempt} />
          {errorMessage && (
            <Text type="help" hasError>
              {errorMessage}
            </Text>
          )}
        </CardBody>
        <CardFooter>
          <BusinessInformationFooter>
            <Text htmlFor="foo" type="label" color="gray">
              Hosted by Stripe
            </Text>
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => void; label: ... Remove this comment to see the full error message */}
            <Button
              type="secondary"
              onClick={(): void => {
                openBillingPortal(true)
                BufferTracker.billingPortalSessionCreated({
                  organizationId: currentOrganization?.id,
                  customerId: currentOrganization?.billing?.gateway?.gatewayId,
                  ctaApp: 'account',
                  ctaView: 'billing',
                  ctaLocation: 'billingInformation',
                  ctaButton: 'updateInformation',
                  ctaVersion: '1',
                })
              }}
              label="Update Information"
            />
          </BusinessInformationFooter>
        </CardFooter>
      </Card>
    </BlockWrapper>
  )
}

BillingInformation.propTypes = {
  taxIds: PropTypes.arrayOf(
    PropTypes.shape({
      country: PropTypes.string,
      value: PropTypes.string,
      verification: PropTypes.shape({
        status: PropTypes.oneOf([
          'pending',
          'verified',
          'unverified',
          'unavailable',
        ]),
        verifiedAddress: PropTypes.string,
        verifiedName: PropTypes.string,
      }),
    }),
  ),
  taxExempt: PropTypes.oneOf(['none', 'exempt', 'reverse']),
  address: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.string,
  }),
}

export default BillingInformation

export const actionTypes = {
  GET_ACCOUNT_REQUEST: 'GET_ACCOUNT_REQUEST',
  GET_ACCOUNT_SUCCESS: 'GET_ACCOUNT_SUCCESS',
  GET_ACCOUNT_FAILURE: 'GET_ACCOUNT_FAILURE',
  /** Other */
  CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION',
  // Mimics keywrapper naming convention of newer actionTypes
  CREDIT_CARD__CC_OPEN_CREDIT_CARD_FORM_MODAL:
    'CREDIT_CARD__CC_OPEN_CREDIT_CARD_FORM_MODAL',
}

const initialState = {
  email: '',
  newEmail: '',
  createdAt: '',
  isLoading: true,
  notificationOpened: false,
  notificationMessage: '',
  featureFlips: [],
  enabledProducts: [],
  isImpersonation: false,
  accountLoaded: false,
  hasVerifiedEmail: null,
  currentOrganization: {
    name: '',
    id: '',
    featureFlips: [],
    channelRecommendations: [],
  },
  organizations: [],
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACCOUNT_SUCCESS: {
      const { account } = action
      const { featureFlips = [], isImpersonation, products } = account

      const enabledProducts = products
        ? // @ts-expect-error TS(7006) FIXME: Parameter 'product' implicitly has an 'any' type.
          products.map((product) => product.name)
        : null

      return {
        ...state,
        ...account,
        featureFlips,
        enabledProducts,
        isImpersonation,
        accountLoaded: true,
        isLoading: false,
      }
    }
    case actionTypes.GET_ACCOUNT_FAILURE: {
      return {
        ...state,
        notificationOpened: true,
        isLoading: false,
        notificationMessage:
          'Sorry! The account information could not be loaded',
      }
    }
    case actionTypes.GET_ACCOUNT_REQUEST: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case actionTypes.CLEAR_NOTIFICATION: {
      return {
        ...state,
        notificationOpened: false,
        notificationMessage: '',
      }
    }
    default:
      return state
  }
}

export const actions = {
  getAccountRequest: () => ({
    type: actionTypes.GET_ACCOUNT_REQUEST,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'account' implicitly has an 'any' type.
  getAccountSuccess: (account) => ({
    type: actionTypes.GET_ACCOUNT_SUCCESS,
    account,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
  getAccountFailure: (error) => ({
    type: actionTypes.GET_ACCOUNT_FAILURE,
    error,
  }),
  clearNotification: () => ({
    type: actionTypes.CLEAR_NOTIFICATION,
  }),
  openCreditCardFormModal: () => ({
    type: actionTypes.CREDIT_CARD__CC_OPEN_CREDIT_CARD_FORM_MODAL,
  }),
}

// NOTE: There is no error code present on the action data for now.
const errors = {
  'Wrong credentials':
    'Whoops! The password seems to be wrong. Mind trying again?',
  'Email already exists':
    'Whoops! This email is already in use. Mind trying again?',
  'Could not update account':
    'We couldn’t update your account. Mind trying again?',
  'Too many requests':
    'Whoops! You’ve made too many email update attempts in a short time. Please try again in 5 minutes.',
}

// @ts-expect-error TS(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
function translateError(error) {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return errors[error] || 'An unknown error occurred. Mind trying again?'
}

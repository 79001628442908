import React from 'react'
import { CardRow, PageCardRow } from '../CardRow'
import Text from '@bufferapp/ui/Text'
import { ChannelBody, LabelWrapper } from './styles'
import partition from 'lodash/partition'
import type { SelectChannelToRemoveProps } from '../CardRow/types'

const Row = ({
  channel,
  canEdit,
  selectChannelToRemove,
  setShowChannelUnlockingModal,
}: {
  channel: any
  canEdit: boolean
  selectChannelToRemove: (arg: SelectChannelToRemoveProps) => void
  setShowChannelUnlockingModal: (show: boolean) => void
}): JSX.Element => {
  if (channel.service === 'startPage') {
    return (
      <PageCardRow
        id={channel.id}
        name={channel.name}
        service={channel.service}
        type={channel.type}
        avatar={channel.avatar}
        isLocked={channel.isLocked}
        selectChannelToRemove={selectChannelToRemove}
        descriptor={channel.descriptor || 'Page'}
      />
    )
  } else {
    // Temporary fix; should be centralized on backend for
    // all clients (web & mobile) in near future
    const needsRefreshing =
      channel.shouldShowPinterestUpgradeBanner ||
      channel.shouldShowLinkedInUpgradeBanner ||
      channel.shouldShowTiktokUpgradeBanner ||
      (channel.service === 'twitter' && channel.apiVersion !== '2')

    return (
      <CardRow
        id={channel.id}
        displayName={channel.displayName}
        name={channel.name}
        service={channel.service}
        type={channel.type}
        descriptor={channel.descriptor || 'Profile'}
        avatar={channel.avatar}
        apiVersion={channel?.apiVersion}
        isDisconnected={channel.isDisconnected}
        needsRefreshing={needsRefreshing}
        isLocked={channel.isLocked}
        selectChannelToRemove={selectChannelToRemove}
        setShowChannelUnlockingModal={setShowChannelUnlockingModal}
        canEdit={canEdit}
        location={channel?.locationData?.location}
        serverUrl={channel?.serverUrl}
      />
    )
  }
}

const ChannelsBody = ({
  canEdit,
  channels,
  selectChannelToRemove,
  setShowChannelUnlockingModal,
}: {
  canEdit: boolean
  channels: any[]
  selectChannelToRemove: (arg: SelectChannelToRemoveProps) => void
  setShowChannelUnlockingModal: (show: boolean) => void
}): JSX.Element => {
  const [lockedChannels, connectedChannels] = partition(
    channels,
    (c) => c.isLocked,
  )

  return (
    <ChannelBody>
      {connectedChannels.map((channel) => (
        <Row
          key={`cardrow-${channel.id}`}
          channel={channel}
          canEdit={canEdit}
          selectChannelToRemove={selectChannelToRemove}
          setShowChannelUnlockingModal={setShowChannelUnlockingModal}
        />
      ))}
      {lockedChannels.length > 0 && (
        <>
          <LabelWrapper>
            <Text type="label">Locked Channels</Text>
          </LabelWrapper>
          {lockedChannels.map((channel) => (
            <Row
              key={`cardrow-${channel.id}`}
              channel={channel}
              canEdit={canEdit}
              selectChannelToRemove={selectChannelToRemove}
              setShowChannelUnlockingModal={setShowChannelUnlockingModal}
            />
          ))}
        </>
      )}
    </ChannelBody>
  )
}

export default ChannelsBody

import { useSplitEnabled } from '@buffer-mono/features'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { Service } from '../../../auth-flows/shared/SelectChannelList/utils'
import { InstagramAuthMethod } from '~/legacy/web/src/components/ChannelConnections/utils'
import { useAccount } from '~/legacy/web/src/context/Account'
import { useApolloClient } from '@apollo/client'
import { GET_ACCOUNT } from '~/legacy/web/src/apollo/queries/account'

type MenuItemsProps = {
  apiVersion?: string
  isLocked: boolean
  canEdit: boolean
  shouldDisableRefresh: boolean
  onUnlockClick: () => void
  selectChannelToRemove: () => void
  service: string
  type: string
  channelId: string
  channelName: string
  channelAvatar: string
  channelUrl: string
}

type MenuItem = {
  id: string
  title: string
  onItemClick: () => void
  disabled: boolean
}

export function useGetMenuItems({
  apiVersion = undefined,
  channelAvatar,
  isLocked,
  channelName,
  channelUrl,
  canEdit,
  shouldDisableRefresh,
  onUnlockClick,
  selectChannelToRemove,
  service,
  type,
  channelId,
}: MenuItemsProps): MenuItem[] {
  const account = useAccount()
  const apollo = useApolloClient()

  const lockedMenuItem = [
    {
      id: '1',
      title: 'Unlock Channel',
      onItemClick: () => onUnlockClick(),
      disabled: !canEdit,
    },
  ]

  const refreshMenuItem = [
    {
      id: '2',
      title: 'Refresh Connection',
      onItemClick: (): void => {
        const { actions } = window.appshell || {}

        actions.connectChannel({
          selectedService: service.toLowerCase(),
          isRefreshingConnection: true,
          cta: `account-channels-channelRefreshDropdown-${service.toLowerCase()}-1`,
          selectedRefreshChannelId: channelId,
          authMethod: apiVersion,
        })
      },
      disabled: !canEdit || shouldDisableRefresh,
    },
  ]

  const { MODALS, actions } = window?.appshell || {}

  const linkToFacebookPage = [
    {
      id: '3',
      title: 'Link Account to Facebook Page',
      onItemClick: () =>
        actions.openModal(MODALS.instagramFacebookReAuth, {
          channelId,
          channelType: type,
        }),
      disabled: false,
    },
  ]

  const disconnectMenuItem = [
    {
      id: '4',
      title: 'Disconnect Channel',
      onItemClick: () => selectChannelToRemove(),
      disabled: !canEdit,
    },
  ]

  const isFacebookGroup = service === 'facebook' && type === 'group'

  if (isFacebookGroup) {
    return [
      ...(isLocked ? lockedMenuItem : []),
      {
        id: '5',
        title: 'Edit Group',
        onItemClick: (): void => {
          actions.openModal(MODALS.facebookSelectAccountType, {
            accountType: 'group',
            isRefreshingConnection: true,
            channelId,
            channelName,
            channelUrl,
            channelAvatar,
            onContinue: () => {
              apollo.refetchQueries({ include: [GET_ACCOUNT] })
            },
          })
        },
        disabled: !canEdit,
      },
      ...disconnectMenuItem,
    ]
  }

  const isInstagramPersonalProfile =
    service === 'instagram' && type === 'profile'

  if (isInstagramPersonalProfile) {
    return [
      ...(isLocked ? lockedMenuItem : []),
      {
        id: '7',
        title: 'Edit Personal Account',
        onItemClick: (): void => {
          actions.openModal(MODALS.instagramSelectAccountType, {
            accountType: 'personal',
            isRefreshingConnection: true,
            channelId,
            channelName,
            channelAvatar,
            onContinue: () => {
              apollo.refetchQueries({ include: [GET_ACCOUNT] })
            },
          })
        },
        disabled: !canEdit,
      },
      {
        id: '6',
        title: 'Switch to a Professional Account',
        onItemClick: (): void => {
          BufferTracker.channelConversionStarted({
            channel: 'instagram',
            clientName: 'publishWeb',
            cta: 'account-channels-menuItem-switchToAProfessionalAccount-1',
            organizationId: account?.currentOrganization?.id || '',
          })

          actions.connectChannel({
            selectedService: Service.instagram,
            customChannelIdToConvert: channelId,
            authMethod: InstagramAuthMethod.instagramLogin,
            cta: 'account-channels-menuItem-switchToAProfessionalAccount-1',
          })
        },
        disabled: !canEdit,
      },
      ...disconnectMenuItem,
    ]
  }

  const isInstagramLoginChannel =
    service === 'instagram' && apiVersion === 'instagramLogin'

  return [
    ...(isLocked ? lockedMenuItem : []),
    ...(!isLocked ? refreshMenuItem : []),
    ...(isInstagramLoginChannel ? linkToFacebookPage : []),
    ...disconnectMenuItem,
  ]
}

import { ChannelThirdPartyService } from '../../../channel-connections/interfaces'

export function getServiceName(
  serviceName: ChannelThirdPartyService,
): ChannelThirdPartyService {
  if (serviceName === ChannelThirdPartyService.instagram_login_api) {
    return ChannelThirdPartyService.instagram
  }

  return serviceName
}

import { LOCATION_CHANGE } from 'connected-react-router'

import { actions as analyticsActions } from '@bufferapp/analytics-middleware'

import { shouldTrackAnalytics, getPath } from './selectors'
import { actionTypes } from './reducer'
import { redirectToLogin } from './utils/redirect'

const MS_BEFORE_REFRESH = 3 * 1000

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function trackPage(state) {
  const path = getPath(state)
  const name = `account${path.length > 1 ? ' ' : ' homepage'}${path
    .replace(/^\//, '')
    .replace('/', ' ')}`
  return analyticsActions.pageChange({ path, name })
}

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)

    switch (action.type) {
      case actionTypes.GET_ACCOUNT_SUCCESS: {
        const state = getState()
        if (shouldTrackAnalytics(state)) {
          // tracking a pageview on account load because the
          // LOCATION_CHANGE action happens before the account
          // has loaded and we skip tracking in that case
          dispatch(trackPage(state))
        }
        break
      }

      case actionTypes.GET_ACCOUNT_FAILURE: {
        redirectToLogin()
        break
      }

      case LOCATION_CHANGE: {
        const state = getState()
        if (shouldTrackAnalytics(state)) {
          dispatch(trackPage(state))
        }

        break
      }

      default:
        break
    }
  }

import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'
import { Services } from '../../../channel-list/constants'

// @ts-expect-error TS(7006) FIXME: Parameter 'serviceName' implicitly has an 'any' ty... Remove this comment to see the full error message
const transformServiceName = (serviceName) => {
  if (serviceName === Services.GOOGLE_BUSINESS_PROFILE) {
    return 'googlebusiness'
  }

  return serviceName
}

export const trackConnectionAttempted = function ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'organizationId' implicitly has an... Remove this comment to see the full error message
  organizationId,
  // @ts-expect-error TS(7031) FIXME: Binding element 'serviceName' implicitly has an 'a... Remove this comment to see the full error message
  serviceName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'cta' implicitly has an 'any' type... Remove this comment to see the full error message
  cta,
  // @ts-expect-error TS(7031) FIXME: Binding element 'planNameAtTimeOfAttempt' implicit... Remove this comment to see the full error message
  planNameAtTimeOfAttempt,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isAtPlanLimit' implicitly has an ... Remove this comment to see the full error message
  isAtPlanLimit,
}) {
  BufferTracker.channelConnectionAttempted({
    organizationId: organizationId || null,
    channel: transformServiceName(serviceName),
    product: 'account',
    clientName: Client.PublishWeb,
    cta: cta || null,
    planNameAtTimeOfAttempt,
    isAtPlanLimit,
  })
}

import React from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  BlockWrapper,
  AnalyzeLogo,
  PublishLogo,
} from '../../../account-components'

import BillingDetailsItem from './BillingDetailsItem'

const BillingDetails = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'subscriptions' implicitly has an ... Remove this comment to see the full error message
  subscriptions,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedOrganizationDetails' impl... Remove this comment to see the full error message
  selectedOrganizationDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'openCancelPlanModal' implicitly h... Remove this comment to see the full error message
  openCancelPlanModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isPayingWithBank' implicitly h... Remove this comment to see the full error message
  isPayingWithBank,
}) => {
  const { analyze, analyzeTrial, publish, publishTrial } = subscriptions
  const hasCardOnFile = !!(
    selectedOrganizationDetails && selectedOrganizationDetails.billing
  )

  return (
    <BlockWrapper>
      <Accordion>
        <BillingDetailsItem
          logo={<PublishLogo width={'85'} height={'20'} />}
          productLink="https://account.buffer.com"
          trialDetails={publishTrial}
          planDetails={publish}
          customerIsPausedWithCoupon={
            selectedOrganizationDetails &&
            selectedOrganizationDetails.customerIsPausedWithCoupon
          }
          hasCardOnFile={hasCardOnFile}
          product={'publish'}
          paidPlanMessage={
            publish && publish.showLegacyPricingMessage
              ? `You’re currently paying $${publish.price} per ${publish.cycle} on the Pro plan with a legacy discount.`
              : ''
          }
          openCancelPlanModal={openCancelPlanModal}
          isPayingWithBank={isPayingWithBank}
        />

        <BillingDetailsItem
          logo={<AnalyzeLogo width={'89'} height={'21'} />}
          productLink="/analyze"
          trialDetails={analyzeTrial}
          planDetails={analyze}
          product={'analyze'}
          hasCardOnFile={hasCardOnFile}
          customerIsPausedWithCoupon={
            selectedOrganizationDetails &&
            selectedOrganizationDetails.customerIsPausedWithCoupon
          }
          isPayingWithBank={isPayingWithBank}
        />
      </Accordion>
    </BlockWrapper>
  )
}

BillingDetails.propTypes = {
  selectedOrganizationDetails: PropTypes.object,
  subscriptions: PropTypes.object,
  updatePlanCallback: PropTypes.func,
  openCancelPlanModal: PropTypes.func,
}

BillingDetails.defaultProps = {
  subscriptions: {},
}

export default BillingDetails

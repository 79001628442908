export enum Service {
  facebook = 'facebook',
  instagram = 'instagram',
  linkedin = 'linkedin',
  pinterest = 'pinterest',
  twitter = 'twitter',
  tiktok = 'tiktok',
  googlebusiness = 'googlebusiness',
  mastodon = 'mastodon',
  youtube = 'youtube',
  threads = 'threads',
  bluesky = 'bluesky',
}

export enum ServiceType {
  business = 'business',
  page = 'page',
  profile = 'profile',
  group = 'group',
  account = 'account',
  channel = 'channel',
}

export interface LocationData {
  location: string
  mapsLink: string
  googleAccountId: string
}
export interface Channel {
  type: ServiceType
  accessToken: string
  accessTokenExpiresAt: Date
  refreshToken: string
  refreshTokenExpiresAt: Date
  avatar: string
  organizationId?: string
  isLocked?: boolean
  // This is sometimes the serviceID, or sometimes the ID from the service OAuth
  // passed from the getChannelsList endpoint
  id: string
  sharedAccessToken: string
  sharedAccessTokenExpiresAt?: Date
  accessTokenSecret: string
  sharedOwner: string
  username: string
  name: string
  serviceId: string
  serverUrl: string
  locationData: LocationData
  connectionAvailability: string
  displayName: string
  // Object ID from MongoDB
  channelId: string
  verified?: boolean
  subscriptionType?: string
  descriptor?: string
}

interface Page {
  id: string
}

interface FormatPagesWithConnectionStatusParams {
  currentOrganizationId: string
  pages: Page[]
  data: {
    canConnectChannels: Channel[]
  }
}

export const formatPagesWithConnectionStatus: object = ({
  pages,
  data,
  currentOrganizationId,
}: FormatPagesWithConnectionStatusParams) => {
  if (pages && data && currentOrganizationId) {
    const { canConnectChannels } = data

    return pages.map((page) => {
      const connectedChannel = canConnectChannels.find(
        (channel) => channel.serviceId === page.id,
      )

      return {
        ...page,
        connectionAvailability: connectedChannel?.connectionAvailability,
      }
    })
  }

  return pages || []
}

export function getChannelType(channel: Channel, service: string): ServiceType {
  if (channel.type) {
    return channel.type
  }

  if (service === Service.youtube) {
    return ServiceType.channel
  }

  const servicesRequiringBusinessChannelType = ['instagram', 'googlebusiness']
  return servicesRequiringBusinessChannelType.includes(service)
    ? ServiceType.business
    : ServiceType.profile
}

export interface ChannelCredentials {
  accessToken: string
  accessTokenExpiresAt?: Date
  accessTokenSecret?: string
  refreshToken?: string
  refreshTokenExpiresAt?: Date
  sharedAccessToken?: string
  sharedAccessTokenExpiresAt?: Date
  sharedOwner?: string
  invalid?: boolean
}

export interface ChannelServiceData {
  subscriptionType?: string
}

export interface ChannelWithCredentialsInput {
  avatar: string
  name: string
  organizationId: string
  products?: Array<string>
  service: Service
  type: ServiceType
  serviceId: string
  locationData: LocationData
  displayName: string
  serverUrl: string
  credentials?: ChannelCredentials
  timezone?: string
  channelId?: string
  verified?: boolean
  serviceData?: ChannelServiceData
}

export type CommonTrackingProperties = {
  atEventBillingState: string
  atEventBillingPlan: string
  atEventBillingCycle: string
  atEventBillingGateway: string
  atEventIsNewBuffer: boolean
  atEventChannelQty: number
}

export interface Account {
  id: string
  email?: string
  channels?: Channel[] | undefined
  currentOrganization: {
    id: string
    channels?: Channel[] | undefined
    canEdit?: boolean
    isOneBufferOrganization?: boolean
    entitlements?: string[]
    createdAt?: Date
    billing?: any
    commonTrackingProperties?: CommonTrackingProperties
    dismissedObjects?: any[]
    privileges?: {
      canManageBilling?: boolean
      canManageChannels?: boolean
    }
    limits: {
      channels: number
    }
    canMigrateToBufferViaHub?: {
      canMigrate: boolean
      reasons: string[]
      subscriptionDiscount: {
        type: string
        duration: string
        value: number
      }
    }
  }
  enabledProducts: string[]
  hasVerifiedEmail?: boolean
  notificationOpened?: boolean
  notificationMessage?: string
}

interface MapRawChannelParams {
  channel: Channel
  account: Account
  service: Service
}

export function mapRawChannel({
  channel,
  account,
  service,
}: MapRawChannelParams): ChannelWithCredentialsInput {
  const fallbackAvatarUrl =
    'https://s3.amazonaws.com/buffer-ui/Default+Avatar.png'

  const {
    accessToken,
    accessTokenExpiresAt,
    refreshToken,
    refreshTokenExpiresAt,
    avatar = fallbackAvatarUrl,
    id,
    sharedAccessToken,
    accessTokenSecret,
    sharedOwner,
    username,
    name,
    serviceId,
    locationData,
    serverUrl,
    displayName,
    channelId,
    verified,
    subscriptionType,
  } = channel || ''

  let timezone
  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    Intl.DateTimeFormat(undefined, { timeZone: timezone })
  } catch (e) {
    timezone = undefined
  }

  const channelInput: ChannelWithCredentialsInput = {
    avatar,
    name: username || name,
    displayName,
    organizationId: account?.currentOrganization?.id,
    products: account?.enabledProducts,
    service,
    serviceId: id || serviceId,
    channelId,
    locationData: {
      googleAccountId: locationData?.googleAccountId,
      location: locationData?.location,
      mapsLink: locationData?.mapsLink,
    },
    serverUrl,
    type: getChannelType(channel, service),
    timezone,
    credentials: {
      accessToken,
      accessTokenExpiresAt,
      refreshToken,
      refreshTokenExpiresAt,
      accessTokenSecret,
      sharedAccessToken,
      sharedOwner,
    },
    verified,
    serviceData: {
      subscriptionType,
    },
  }

  // 'credentials' is a required property during channel connection.
  // However, since we're rolling out preventing passing credentials to the
  // frontend, 'credentials' will always be present and set to {}.
  // The code below removes the 'credentials' property for
  // these services since credentials will be fetched from our temp
  // collection in the Core API on channel connection.
  const servicesUsingTemporaryCredentials = [
    'linkedin',
    'facebook',
    'twitter',
    'instagram',
    'googlebusiness',
    'youtube',
  ]
  if (servicesUsingTemporaryCredentials.includes(service)) {
    delete channelInput.credentials
  }
  return channelInput
}

export interface ChannelAuthorizationInput {
  code: string
  organizationId: string
  service: Service
}

export interface ChannelAuthorizationUrlInput {
  service: Service
  redirectUri: string
  state: string
}

export interface CredentialsForChannelsInput {
  code: string
  service: Service
  redirectUri: string
  organizationId?: string
}

import styled from 'styled-components'

export const TitleContainer = styled.div`
  margin: 32px 0 16px;
  /* for mobile */
  @media (max-width: 768px) {
    width: 100%;
  }
  width: 512px;
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* for mobile */
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

import React from 'react'

import { Text, Modal, Avatar } from '@bufferapp/ui'
import { Header, Body, ChannelWrapper } from './style'
import { NameStyle, TextStyled } from '../../../style'
import { fallbackAvatarUrl } from '../../ChannelsPage'
import type { SelectChannelToRemoveProps } from '../../../components/CardRow/types'

const RemoveChannelModal = ({
  selectedChannel,
  selectChannelToRemove,
}: {
  selectedChannel: SelectChannelToRemoveProps
  selectChannelToRemove: (arg?: SelectChannelToRemoveProps) => void
}): JSX.Element => {
  const isStartPage = selectedChannel.service === 'startPage'

  const isFacebookGroup =
    selectedChannel.service === 'facebook' && selectedChannel.type === 'group'

  const isCustomChannel = isFacebookGroup

  return (
    // @ts-expect-error TS(2322)
    <Modal
      action={{
        label: isStartPage ? 'Confirm And Delete' : 'Disconnect Channel',
        callback: (): void =>
          selectChannelToRemove({
            ...selectedChannel,
            confirmed: true,
          }),
        type: 'danger',
      }}
      width={isStartPage ? '555px' : '512px'}
      secondaryAction={{
        label: 'Cancel',
        callback: (): void => selectChannelToRemove(undefined),
      }}
      dismissible={false}
    >
      <Header>
        <Text type="h2">
          {isStartPage
            ? 'Are you sure you want to delete this Start Page?'
            : 'You’re about to disconnect this channel from your organization.'}
        </Text>
      </Header>
      <Body>
        <ChannelWrapper>
          <Avatar
            size="medium"
            src={selectedChannel.avatar}
            alt={selectedChannel.name}
            network={selectedChannel.service}
            fallbackUrl={fallbackAvatarUrl}
          />
          <NameStyle>
            <TextStyled type="p" title={selectedChannel.name}>
              {selectedChannel.name}
            </TextStyled>
            <Text type="p">
              {isStartPage ? 'Start Page' : selectedChannel.descriptor}
            </Text>
          </NameStyle>
        </ChannelWrapper>

        {isStartPage && (
          <Text type="p">
            Your Start Page will be immediately deleted and your data will be
            lost. Visitors will no longer be able to access your page using the
            link.
          </Text>
        )}

        {!isStartPage && (
          <Text type="p">
            You will no longer be able to post to this account, all existing
            posts, analytics, campaigns, and data will be deleted and{' '}
            <strong>cannot be recovered</strong>.{' '}
            <strong color="red">This action cannot be undone.</strong>
          </Text>
        )}

        {!isCustomChannel && (
          <>
            <br />
            <Text type="p">
              Having issues with your connection? We advise using the refresh
              connection option.
            </Text>
          </>
        )}
      </Body>
    </Modal>
  )
}

export default RemoveChannelModal

import {
  asyncDataFetchActions,
  asyncDataFetchActionTypes,
} from '@buffer-mono/async-data-fetch'
import { actionTypes, actions } from './reducer'
import { actionTypes as cancelPlanActions } from '../cancel-plan/reducer'
import {
  actions as creditCardActions,
  actionTypes as creditCardActionTypes,
} from '../credit-card/reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  switch (action.type) {
    case actionTypes.FETCH_INVOICES_REQUEST: {
      const { status, stripeCustomerId, invoiceId, limit } = action
      store.dispatch(
        asyncDataFetchActions.fetch({
          name: 'getBillingInvoices',
          args: {
            status,
            limit,
            invoiceId,
            stripeCustomerId,
          },
        }),
      )
      break
    }
    case creditCardActionTypes.CC_CARD_SAVE_SUCCESS:
      setTimeout(() => {
        store.dispatch(creditCardActions.resetCreditCardNotification())
      }, 5000)

      break
    case `updatePlan_${asyncDataFetchActionTypes.FETCH_SUCCESS}`:
      store.dispatch(actions.closeChangePlanModal())

      setTimeout(() => {
        store.dispatch(actions.closeChangePlanNotification())
      }, 7000)
      break
    case `updatePlan_${asyncDataFetchActionTypes.FETCH_FAIL}`:
      // Dispatch error message
      break
    case actionTypes.CHANGE_PLAN_REQUEST:
      store.dispatch(
        asyncDataFetchActions.fetch({
          name: 'updatePlan',
          args: {
            plan: action.plan,
            cycle: action.cycle,
            product: action.product,
          },
        }),
      )
      break
    case cancelPlanActions.CLOSE_CHURN_SURVEY_MODAL:
      // Re-fetches billing information so user sees their updated plan
      // details after successfully canceling a plan.
      store.dispatch(
        asyncDataFetchActions.fetch({
          name: 'getBillingDetails',
          args: {},
        }),
      )
      break
    default:
      break
  }
  return next(action)
}

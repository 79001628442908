import type { Account } from '../../Channels/auth-flows/shared/SelectChannelList/utils'

export function isOnNonAgencyTieredPricing(account: Account): boolean {
  if (
    !account.currentOrganization ||
    !account.currentOrganization.billing ||
    !account.currentOrganization.billing.pricingTiers
  ) {
    return false
  }
  // Non-agency tiered plans are the only ones with more than 2 pricing tiers
  return account.currentOrganization.billing.pricingTiers.length > 2
}

import styled from 'styled-components'
import {
  BlockWrapper,
  TitleContainer,
} from '../../../../../../account-components'

export const Footer = styled.section`
  width: 100%;
  text-align: left;
  margin-top: 2.5rem;
  margin-bottom: 50px;
`
export const FooterLink = styled.a`
  margin-left: 0.5rem;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }

  & > * + * {
    margin-left: 0.5rem;
  }
`

export const WarningMessageWrapper = styled.div`
  /* for mobile */
  @media (max-width: 768px) {
    width: 100%;
  }
  width: 512px;

  margin-bottom: 16px;
`

export const BlockWrapperStyled = styled(BlockWrapper)`
  width: 600px;
`

export const TitleContainerStyled = styled(TitleContainer)<{
  wide?: boolean
}>`
  display: flex;
  justify-content: space-between;
  gap: var(--space-sm);
  width: 100%;
  max-width: ${(props): string => (props.wide ? '700px' : '600px')};

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

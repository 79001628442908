import React from 'react'
import { grayDark, green } from '@bufferapp/ui/style/colors'

import Reconnect from '../Reconnect'

import { CriticalIcon, Tooltip } from '@buffer-mono/popcorn'
import LockedIcon from '@bufferapp/ui/Icon/Icons/Locked'
import { CircleCheckmarkIcon } from '~/legacy/account-components/index'

import styles from './styles.module.css'

type StatusSectionArgs = {
  canEdit?: boolean
  isDisconnected?: boolean
  isLocked?: boolean
  onRefreshConnectionClick?: () => void
  needsRefreshing?: boolean
}

function getState(
  isLocked?: boolean,
  needsRefreshing?: boolean,
  isDisconnected?: boolean,
): 'locked' | 'active' | 'requiresRefreshing' {
  if (isLocked) {
    return 'locked'
  }

  if (!isDisconnected && !needsRefreshing) {
    return 'active'
  }

  return 'requiresRefreshing'
}

const StatusSection = ({
  canEdit,
  isDisconnected,
  isLocked,
  onRefreshConnectionClick,
  needsRefreshing,
}: StatusSectionArgs): JSX.Element => {
  const state = getState(isLocked, needsRefreshing, isDisconnected)

  return (
    <div className={styles.channelStatusWrapper}>
      {state === 'locked' && (
        <Tooltip
          content={
            <span>
              <strong>Locked.</strong> You&apos;ve reached the maximum number of
              channels allowed for your plan. Upgrade to continue using this
              channel.
            </span>
          }
        >
          <div className={styles.statusWrapper}>
            <LockedIcon data-testid="locked-icon" color={grayDark} />
          </div>
        </Tooltip>
      )}
      {state === 'active' && (
        <Tooltip
          content={
            <span>
              <strong>Active.</strong> You&apos;re all set. 👍
            </span>
          }
        >
          <div className={styles.statusWrapper}>
            <CircleCheckmarkIcon data-testid="active-icon" color={green} />
          </div>
        </Tooltip>
      )}
      {state === 'requiresRefreshing' && (
        <>
          <div className={styles.statusWrapper}>
            <Reconnect
              canEdit={canEdit}
              onRefreshConnectionClick={onRefreshConnectionClick}
            />
          </div>
          <div className={styles.statusWrapper}>
            <Tooltip
              content={
                needsRefreshing ? (
                  <span>
                    <strong>Needs refreshing.</strong> Please refresh this
                    connection to continue sending your posts as usual.
                  </span>
                ) : (
                  <span>
                    <strong>Expired.</strong> Refresh your connection to resume
                    using this channel.
                  </span>
                )
              }
            >
              <CriticalIcon data-testid="warning-icon" color="critical" />
            </Tooltip>
          </div>
        </>
      )}
    </div>
  )
}

export default StatusSection
